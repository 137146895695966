import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Sidebar, { sidebarDatabase } from './Sidebar'
import SubPage from './Subpage';


const ConfigurationPage = () => {
  const [selectedOption, setSelectedOption] = useState(sidebarDatabase[0]);
  const [page, setPage] = useState('General');

  useEffect(() => {
    setPage(selectedOption.page)
  }, [selectedOption])


  return (
    <Box sx={{
      backgroundColor: '#F7F7F8',
      minHeight: '100vh',
      display: 'flex'
    }}>
      <Sidebar selected={selectedOption} setSelected={setSelectedOption} />
      <SubPage page={page} />
    </Box>
  )
}

export default ConfigurationPage