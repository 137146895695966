import React, { useEffect, useState } from 'react'
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import { isAuthenticated } from '../../authMiddleware';
import QRCODE2 from '../../assets/images/QRCODE2.png';
import { io } from 'socket.io-client';
import Step3 from './steps/Step3';
import { useNavigate } from 'react-router-dom';
import PixSuccess from './steps/PixSuccess';
import BoletoSucess from './steps/BoletoSuccess';

const usePayment = () => {
    const selectedPlan = JSON.parse(localStorage.getItem("selected_plan"));

    // Authentication
    const { authenticated } = isAuthenticated();

    // State Variables
    const [currentStep, setCurrentStep] = useState(1);
    const [userInfo, setUserInfo] = useState({});
    const [copied, setCopied] = useState(false);
    const [codeGenerated, setCodeGenerated] = useState(false);
    const [qrcode, setQrcode] = useState(QRCODE2);
    const [isConnected, setIsConnected] = useState(false);
    const [pixCode, setPixCode] = useState("");
    const [paymentId, setPaymentId] = useState("");
    const [paymentStatus, setPaymentStatus] = useState("");
    const [token, setToken] = useState(null);
    const [months, setMonths] = useState(1);
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [phone, setPhone] = useState("");
    const [cel, setCel] = useState("");
    const [cep, setCep] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [street, setStreet] = useState("");
    const [number, setNumber] = useState("");

    const calculateValues = (price, months, discountRate) => {
        const firstMonthPrice = price;  // O primeiro mês é sempre o valor cheio
        const discountedPrice = price * (1 - discountRate);  // Desconto de 10% por mês, exceto no 1º mês

        const subtotal = firstMonthPrice + discountedPrice * (months - 1);  // Total dos primeiros 6 meses
        const fullPrice = price * months;  // Valor sem desconto para todos os meses
        const savings = fullPrice - subtotal;  // Economia total

        return {
            subtotal: subtotal,
            savings: savings,
        };
    };

    const subtotal = calculateValues(selectedPlan.price, months, 0.10).subtotal;
    const economy = calculateValues(selectedPlan.price, months, 0.10).savings;
    const navigate = useNavigate();

    useEffect(() => {
        if (!selectedPlan) {
            navigate("/painel");
        }
    }, [])

    useEffect(() => {

        const fetchToken = async () => {

            // const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

            // try {
            //     const response = await fetch(`${REACT_APP_BACKEND_URL}/auth/portal`, {
            //         method: 'POST',
            //         headers: { 'Content-Type': 'application/json' },
            //         body: JSON.stringify({ cpf_cnpj: userInfo.CPF_CNPJ }),
            //     });

            //     if (!response.ok) {
            //         throw new Error('Falha ao obter o token');
            //     }

            //     const data = await response.json();
            //     setToken(data.token);
            // } catch (error) {
            //     console.error('Erro ao buscar token:', error);
            // }
        };

        if (paymentId && userInfo.CPF_CNPJ) {
            fetchToken();
        }
    }, [paymentId]);

    useEffect(() => {
        if (token && paymentStatus !== 'CONFIRMADO') {
            const REACT_APP_BACKEND_SOCKET_URL = process.env.REACT_APP_BACKEND_SOCKET_URL;
            const socket = io(REACT_APP_BACKEND_SOCKET_URL, {
                reconnection: true,
                reconnectionAttempts: 5,
                reconnectionDelay: 30000,
                query: { token, txid: paymentId }
            });

            const handleConnect = () => {
                console.log('Conectado ao servidor Flask via SocketIO!');
                setIsConnected(true);
            };

            const handleDisconnect = () => {
                console.log('Desconectado do servidor Flask.');
                setIsConnected(false);
            };

            const handleReconnect = (attemptNumber) => {
                console.log(`Reconectado ao servidor Flask após ${attemptNumber} tentativas.`);
            };

            const handleReconnectError = () => {
                console.error('Erro ao tentar reconectar ao servidor Flask.');
            };

            const handleResponse = (data) => {
                console.log('Recebido do servidor:', data);
            };

            const handleMessage = (data) => {
                console.log('Mensagem da fila:', data);
                if (data.data.status === 'received') {
                    setPaymentStatus('CONFIRMADO');
                    console.log('Pagamento confirmado!');
                } else {
                    console.log('Pagamento não confirmado!');
                }
            };

            socket.on('connect', handleConnect);
            socket.on('disconnect', handleDisconnect);
            socket.on('reconnect', handleReconnect);
            socket.on('reconnect_error', handleReconnectError);
            socket.on('response', handleResponse);
            socket.on('message', handleMessage);

            return () => {
                socket.off('connect', handleConnect);
                socket.off('disconnect', handleDisconnect);
                socket.off('reconnect', handleReconnect);
                socket.off('reconnect_error', handleReconnectError);
                socket.off('response', handleResponse);
                socket.off('message', handleMessage);
                socket.disconnect();
            };
        }
    }, [token, paymentStatus]);

    useEffect(() => {
        if (authenticated) {
            const { decryptedData } = isAuthenticated();
            setUserInfo(decryptedData);
        }
    }, [authenticated]);

    const renderCurrentStep = () => {
        switch (currentStep) {
            case 1:
                return <Step1 product={selectedPlan} setCurrentStep={setCurrentStep} setMonths={setMonths} months={months} subtotal={subtotal} economy={economy} />;
            case 2:
                return <Step2 cep={cep} setCep={setCep} city={city} name={name} number={number} product={selectedPlan} setName={setName} setCity={setCity} setNumber={setNumber} setState={setState} setStreet={setStreet} state={state} street={street} months={months} setCurrentStep={setCurrentStep} cel={cel} phone={phone} setCel={setCel} setPhone={setPhone} setSurname={setSurname} surname={surname} economy={economy} subtotal={subtotal} />;
            case 3:
                return <Step3 cep={cep} setCep={setCep} city={city} name={name} number={number} product={selectedPlan} setName={setName} setCity={setCity} setNumber={setNumber} setState={setState} setStreet={setStreet} state={state} street={street} months={months} setCurrentStep={setCurrentStep} cel={cel} phone={phone} setCel={setCel} setPhone={setPhone} setSurname={setSurname} surname={surname} economy={economy} subtotal={subtotal} pixCode={pixCode} qrCode={qrcode} />;
            case 4:
                return <PixSuccess />
            case 5:
                return <BoletoSucess />
            default:
                return null;
        }
    };

    return {
        renderCurrentStep,
        currentStep,
        setCurrentStep
    }
}

export default usePayment