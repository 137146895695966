// Description: Component that return a status label
// Update: 12/08/2024

// React Components and DOM Elements
import React from 'react';

// Components and functions from the project
import ThemeColors from '../ThemeColors/ThemeColors';

import { Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CloseIcon from '@mui/icons-material/Close';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import HelpOutlineSharpIcon from '@mui/icons-material/HelpOutlineSharp';
const statusStyles = {
    'Ativo': {
        backgroundColor: '#E9F5E3',
        color: '#82BD69',
        fontSize: '14px',
    },
    'Aguardando pagamento': {
        backgroundColor: '#F6EDCB',
        color: '#DEA73F',   
        fontSize: '14px',
    },
    'Arquivo': {
        backgroundColor: 'gray',
        color: '#000',
        fontSize: '14px',
        borderRadius: '30px',
    },
    'Ativada': {
        backgroundColor: '#E9F5E3',
        color: '#82BD69',
        fontSize: '14px',
        borderRadius: '30px',
    },
    'Atrasos': {
        backgroundColor: ThemeColors.purple700,
        color: '#fff',
        borderRadius: '30px',
        fontSize: '14px',
    },

    'Avisos': {
        backgroundColor: ThemeColors.purple700,
        color: '#fff',
        borderRadius: '30px',
        fontSize: '14px',
    },
    'Geral': {
        backgroundColor: ThemeColors.purple900,
        color: '#fff',
        fontSize: '14px',
        borderRadius: '30px',
    },
    'Baixa': {
        backgroundColor: '#DDD4FF',
        color: '#8142FC',
        fontSize: '14px',
        borderRadius: '30px',
    },
    'Comercial': {
        backgroundColor: ThemeColors.purple800,
        color: '#fff',
        fontSize: '14px',
        borderRadius: '30px',
    },
    'Conectado': {
        background: '#E9F5E3',
        color: '#82BD69',
        fontSize: '14px',
    },
    'Desativado': {
        backgroundColor: '#FCEAE7',
        color: '#E98A83',
        fontSize: '14px',
    },
    'Desconectado': {
        background: '#FCEAE7',
        color: '#E98A83',
        fontSize: '14px',
    },
    'Pausado': {
        background: '#FCEAE7',
        color: '#E98A83',
        fontSize: '14px',
    },
    'Desconhecido': {
        backgroundColor: ThemeColors.gray20,
        fontSize: '14px',
    },
    'Desapproved': {
        backgroundColor: '#FCEAE7',
        color: '#E98A83',
        fontSize: '14px',
    },
    'ATIVA': {
        backgroundColor: ThemeColors.yellow01,
        color: ThemeColors.yellow03,
        fontSize: '16px',
    },
    'CONCLUIDA': {
        backgroundColor: ThemeColors.green01,
        color: ThemeColors.green03,
        borderRadius: '30px',
        fontSize: '14px',
    },
    'Em andamento': {
        background: 'gray',
        color: '#000',
        fontSize: '14px',
    },
    'Em uso': {
        background: '#E9F5E3',
        color: '#82BD69',
        fontSize: '14px',
    },
    'Entregue': {
        backgroundColor: ThemeColors.gray20,
        fontSize: '14px',
    },
    'Erro': {
        backgroundColor: '#FCEAE7',
        color: '#E98A83',
        fontSize: '14px',
    },
    'Espera': {
        backgroundColor: '#F6EDCB',
        color: '#DEA73F',
        fontSize: '14px',
    },
    'Processando': {
        backgroundColor: '#CBDFF6',
        color: '#1E22AA',
        fontSize: '16px',
    },
    'Faturas': {
        backgroundColor: ThemeColors.purple800,
        color: '#fff',
        borderRadius: '30px',
        fontSize: '14px',
    },
    'Falha': {
        backgroundColor: '#FCEAE7',
        color: '#E98A83',
        fontSize: '14px',
    },
    'Financeiro': {
        backgroundColor: ThemeColors.purple950,
        color: '#fff',
        fontSize: '14px',
        borderRadius: '30px',
    },
    'Google planilhas': {
        backgroundColor: '#FFF',
        color: '#34A853',
        fontSize: { sm: '11px', lg: '14px'},
        fontFamily: 'Satoshi-Medium',
        borderRadius: '30px',
        border: '1px solid gray',
    },
    'Indisponível': {
        background: ThemeColors.gray30,
        color: ThemeColors.gray40,
        fontSize: '14px',
    },
    'Inadimplências': {
        background: ThemeColors.purple900,
        color: '#fff',
        borderRadius: '30px',
        fontSize: '14px',
    },
    'Lido': {
        backgroundColor: ThemeColors.gray20,
        fontSize: '14px',
    },
    'Marketing': {
        background: ThemeColors.purple950,
        color: '#fff',
        borderRadius: '30px',
        fontSize: '14px',
    },
    'Média': {
        backgroundColor: '#C5B2FF',
        color: '#7121E0',
        fontSize: '14px',
    },
    'API': {
        backgroundColor: '#C5B2FF',
        color: '#7121E0',
        fontSize: '14px',
        borderRadius: '30px',   
    },

    'Online': {
        backgroundColor: 'gray',
        color: '#000',
        fontSize: '14px',
        borderRadius: '30px',
    },
    Reprovado: {
        backgroundColor: '#FCEAE7',
        color: '#E98A83',
        fontSize: '14px',
    },
    'Outros': {
        backgroundColor: ThemeColors.purple950,
        color: '#fff',
        fontSize: '14px',
        borderRadius: '30px',
    },
    'Pending': {
        backgroundColor: '#F6EDCB',
        color: '#DEA73F',
        fontSize: '14px',
    },
    'Pendente': {
        backgroundColor: '#F6EDCB',
        color: '#DEA73F',
        fontSize: '14px',
    },
    'Reclamação': {
        backgroundColor: ThemeColors.purple700,
        color: '#fff',
        fontSize: '14px',
        borderRadius: '30px',
    },
    'Técnico': {
        backgroundColor: ThemeColors.purple900,
        color: '#fff',
        fontSize: '14px',
        borderRadius: '30px',
    },
    'RECEBIDO': {
        backgroundColor: ThemeColors.green01,
        color: ThemeColors.green03,
        fontSize: '14px',
    },
    'PERSON': {
        backgroundColor: '#E9F5E3',
        color: '#82BD69',
        fontSize: '14px',
    },
    'ORGANIZATION': {
        backgroundColor: '#F6EDCB',
        color: '#DEA73F',
        fontSize: '14px',
    },
    'LOCATION': {
        backgroundColor: '#CBDFF6',
        color: '#3A8DFF',
        fontSize: '14px',
    },
    'DATE': {
        backgroundColor: '#E9F5E3',
        color: '#82BD69',
        fontSize: '14px',
    },
    'EVENT': {
        backgroundColor: '#FCEAE7',
        color: '#E98A83',
        fontSize: '14px',
    },
    'TITLE': {
        backgroundColor: '#FFF',
        color: '#34A853',
        fontSize: '14px',
    },
    'QUANTITY': {
        backgroundColor: '#E9F5E3',
        color: '#82BD69',
        fontSize: '14px',
    },
    'OTHER': {
        backgroundColor: ThemeColors.gray20,
        fontSize: '14px',
    },
    'Alta': {
        backgroundColor: '#A986FF',
        color: '#000',
        fontSize: '14px',
    },
    'POST': {
        backgroundColor: '#E9F5E3',
        color: '#82BD69',
        fontSize: '14px',
    },
    'GET': {
        backgroundColor: '#F6EDCB',
        color: '#DEA73F',
        fontSize: '14px',
    },
    'selected': {
        backgroundColor: '#7121E0',
        color: '#fff',
        fontSize: '14px',
        borderRadius: '30px',
    },

    'Processada': {
        backgroundColor: '#C5B2FF',
        color: '#7121E0',
        fontSize: '14px',
    },

    'Aberta': {
        backgroundColor: '#F6EDCB',
        color: '#DEA73F',
        fontSize: '14px',
    },
    'TRANSFERIDO': {
        backgroundColor: '#FCEAE7',
        color: '#E98A83',
        fontSize: '14px',
    },
    'Taxa': {
        backgroundColor: ThemeColors.red01,
        color: ThemeColors.red03,
        fontSize: '16x',
    },
    'PENDING': {
        backgroundColor: '#F8D6D3',
        color: '#4E1B1D',
        fontSize: '14px',
    },
    'Verificando': {
        backgroundColor: '#CBDFF6',
        color: '#3A8DFF',
        fontSize: '14px',
    },
    'Habilitado': {
        backgroundColor: ThemeColors.green01,
        color: ThemeColors.green03,
        fontSize: '14px',
    },

    'Desabilitado': {
        backgroundColor: ThemeColors.red01,
        color: ThemeColors.red03,
        fontSize: '14px',
    },
};


const StatusLabel = ({ status, widthLabel, heightLabel, usingOnTable, icon }) => {
    const style = {
        padding: { lg: '10px', xl: '10px', xs: '7px' },
        borderRadius: '4px',
        fontFamily: 'Satoshi',
        margin: usingOnTable ? '' : '0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        minWidth: widthLabel || { lg: '150px', xl: '150px', sm: '100px' },
        maxWidth: widthLabel || { lg: '150px', xl: '150px', sm: '100px' },
        minHeight: heightLabel || 'auto',
        maxHeight: heightLabel || 'auto',
        ...statusStyles[status]
    };

    const styleBox = {

        padding: '15px',
        borderRadius: '4px',
        fontFamily: 'Satoshi',
        margin: usingOnTable ? '' : '0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        minHeight: heightLabel || 'auto',
        maxHeight: heightLabel || 'auto',
        ...statusStyles[status]

    };


    const text = {
        Active: 'Ativo',
        ATIVA: 'Em Aberto',
        Aberta: 'Em Aberto',
        'CONCLUIDA': 'Recebido',
        Alta: 'Alta',
        Reprovado: 'Reprovado',
        Desativado: 'Desativado',
        Pending: 'Pendente',
        Pendente: 'Pendente',
        Avisos: 'Avisos',
        Faturas: 'Faturas',
        Inadimplências: 'Inadimplências',
        Outros: 'Outros',
        Atrasos: 'Atrasos',
        Marketing: 'Marketing',
        Conectado: 'Conectado',
        Desconectado: 'Desconectado',
        Ativo: 'Liberado',
        Fechada: 'Fechada',
        'Em andamento': 'Em andamento',
        Média: 'Média',
        Baixa: 'Baixa',
        Online: 'Online',
        Arquivo: 'Arquivo',
        Indisponível: 'Indisponível',
        'Google planilhas': 'Google Planilhas',
        'RECEBIDO': 'Recebido',
        'Em uso': 'Em uso',
        Financeiro: 'Financeiro',
        Técnico: 'Técnico',
        Comercial: 'Comercial',
        Reclamação: 'Reclamação',
        Elogio: 'Elogio',
        Outros: 'Outros',
        'Erro': 'Erro',
        'Falha': <CloseIcon style={{ color: ThemeColors.red03 }} />,
        Lido: <DoneAllIcon style={{ color: ThemeColors.purple500 }} />,
        'Desconhecido': <QuestionMarkIcon style={{ color: ThemeColors.gray40 }} />,
        'Espera': 'Espera',
        'Processando': 'Processando',
        Processada: 'Processada',
        'Entregue': <CheckIcon style={{ color: ThemeColors.gray40 }} />,
        'PERSON': 'Pessoa',
        'ORGANIZATION': 'Organização',
        'LOCATION': 'Localização',
        'DATE': 'Data',
        'EVENT': 'Evento',
        'TITLE': 'Título',
        'QUANTITY': 'Quantidade',
        'OTHER': 'Outro',
        'POST': 'POST',
        'GET': 'GET',
        'API': 'API',
        'selected': 'Seleção',
        Pausado: 'Pausado',
        "TRANSFERIDO": 'Transferido',
        'Taxa' : 'Taxa',
        "PENDING" : 'Pendente',
        'Geral': 'Geral',
        'Verificando': 'Verificando',
        'Habilitado': 'Habilitado',
        'Desabilitado': 'Desabilitado',
    };

    return (
        <>
            {
                usingOnTable === true && (
                    <Box display={'flex'} justifyContent={'start'} minWidth={'100%'}>
                        <Box component={'span'} sx={status === 'Lido' || status === 'Entregue' || status === 'Falha' || status === 'Desconhecido' ?
                            styleBox : style} title={status}>
                            {text[status]}
                        </Box>
                    </Box>
                ) || (
                    <Box component={'span'} sx={style} title={status}>
                        {text[status]}
                    </Box>
                )
            }
        </>
    );
};

export default StatusLabel;
