// Description: Page to update the templates that will be rendered with HTML strings
// Updated: 21/10/2024
// Author: Simon Franklin

// React
import React, { useState, useEffect } from "react";

// Material UI Components
import { Box } from "@mui/material";

// Components and functions for the project
import HeaderPage from "../../components/HeaderPage/HeaderPage";
import DynamicForm from "../../components/HTMLForm/DynamicForm";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "../../authMiddleware";
import { GetVariables } from "../../api/backend/Templates/GetVariables";
import {
  initialHtml,
  personalizedBackgrounds,
  mapHTMLColors,
} from "../../utils/templates";
import { createObjectFromArray } from "../../utils/utils";
import { GetAllTemplates } from "../../api/backend/Templates/GetAllTemplates";
import Loading from "../../components/Loading/Loading";
import { TemplateUpdate } from "../../api/backend/Templates/UpdateTemplate";
import themeColors from "../../components/ThemeColors/ThemeColors";

// Render your component
const EditTemplateHTML = () => {
  // Authentication and state variables
  const { userAuthenticated } = useAuth();

  // Template Id State
  const [templateId, setTemplateId] = useState(
    JSON.parse(localStorage.getItem("templateId")) || null
  );
  const [title, setTitle] = useState("");
  const [emailTitle, setEmailTitle] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [variablesOfTemplate, setVariablesOfTemplate] = useState({});
  const [typeMessage, setTypeMessage] = useState("Atrasos");
  const [backgroundColor, setBackgroundColor] = useState("#6558de");
  const palletOfColors = [
    "#BB0404", "#DEA73F", "#FFFFFF", "#1837D7", "#ED15BE", "#6558de"
  ];
  const [selected, setSelected] = useState("Atrasos");
  const [htmlToBeRendered, setHtmlToBeRendered] = useState(initialHtml
    .replace("$json.Title01", "Title01")
    .replace("$json.Title02", "URL01")
    .replace("{ Background }", backgroundColor)
    .replace("{ TitleColor }", backgroundColor === "#FFFFFF" ? themeColors.purple500 : "#FFFFFF"));

  // Function to extract variables
  const extractVariables = (html) => {
    const pattern = /{{\s*(.*?)\s*}}/g;
    let variables = [];
    let match;
    while ((match = pattern.exec(html)) !== null) {
      variables.push(match[1]);
    }
    return variables;
  };

  const [variables, setVariables] = useState(extractVariables(htmlToBeRendered));
  const [formState, setFormState] = useState(createObjectFromArray(variables, ""));

  // Function to replace the dynamic variables in the HTML
  const replaceVariables = (html, formState) => {
    return html.replace(/{{\s*(.*?)\s*}}/g, (match, p1) => {
      const value = formState[p1] || "";

      return value.replace(/\n/g, '<br />');
    });
  };

  // updated HTML with the value of the inputs
  const updatedHtml = replaceVariables(htmlToBeRendered, formState);

  // Loading Transition state
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    setHtmlToBeRendered(initialHtml
      .replace("$json.Title01", "Title01")
      .replace("$json.Title02", "URL01")
      .replace("{ Background }", backgroundColor)
      .replace("{ TitleColor }", backgroundColor === "#FFFFFF" ? themeColors.purple500 : "#FFFFFF"))
  }, [backgroundColor])

  const Navigate = useNavigate();

  // Effect to Check Authentication and Fetch User Info
  useEffect(() => {
    if (userAuthenticated) {
      const { decryptedData } = isAuthenticated();
      setUserInfo(decryptedData);
    } else {
      Navigate("/");
    }
  }, []);

  // Function to get the template data
  const fetchTemplatesData = async () => {
    try {

      const templatesData = await GetAllTemplates();

      console.log(templatesData);

      const items = templatesData
        .map((template) => {
          return {
            id: template.id,
            title: template.title,
            status: template.status,
            msg_type: template.msg_type,
            channel_type: template.channel_type,
            type: template.type,
            background: template.background,
            message_json: template.message_json,
          };
        })
        .filter((item) => item !== null);

      const findTemplate = items.find((item) => item.id === templateId);

      setSelected(findTemplate.msg_type);
      setTitle(findTemplate.title);
      setEmailTitle(findTemplate.message_json.template.variables[0].emailTitle);

      const newVariables = extractVariables(
        findTemplate.message_json.template.html
      );

      setVariables(newVariables);

      const findUrl = newVariables.find((variable) => variable === "URL01");

      findUrl
        ? setFormState({
          Title01: findTemplate.message_json.template.variables[0].title01,
          URL01: findTemplate.message_json.template.variables[0].Url01,
          Logo: findTemplate.message_json.template.variables[0].logo
        })
        : setFormState({
          Title01: findTemplate.message_json.template.variables[0].title01,
          Text: findTemplate.message_json.template.variables[0].text,
          Logo: findTemplate.message_json.template.variables[0].logo
        });

      setTypeMessage(findTemplate.msg_type);
      setHtmlToBeRendered(findTemplate.message_json.template.html);
      setBackgroundColor(findTemplate.message_json.template.variables[0].themeColor);
    } catch (error) {
      console.error("Erro ao obter modelos:", error);
    } finally {
      setShowLoading(false);
    }
  };

  // Effect to Fetch Data from API
  useEffect(() => {
    if (userInfo["UUID"]) {
      fetchTemplateVariables();

      if (templateId === null) {
        Navigate("/modelos");
        return;
      }

      fetchTemplatesData();
    }
  }, [userInfo]);

  // Catch template variables
  const fetchTemplateVariables = async () => {
    try {
      const translations = {
        Personal_information: "Informações Pessoais",
        Contact: "Contato",
        Address: "Endereço",
        Financial_References: "Referências Financeiras",
      };

      const resultJson = await GetVariables();
      const result = resultJson.reduce((acc, item) => {
        const category = translations[item[1]] || item[1]; // Traduz a categoria, ou usa o nome original caso não haja tradução
        if (!acc[category]) {
          acc[category] = [];
        }

        // limita a 10 itens

        acc[category].push(item[0]);

        return acc;
      }, {});

      console.log(result, "Template Variables");

      setVariablesOfTemplate(result);
    } catch (error) {
      console.error("Error fetching data from API:", error);
    }
  };

  // Function to edit the template
  const updateTemplate = async () => {
    const template = {
      template: {
        html: htmlToBeRendered,
        variables: [
          {
            emailTitle: emailTitle,
            title01: formState.Title01,
            logo: formState.Logo,
            text: formState.Text,
            themeColor: backgroundColor
          },
        ],
      },
    };

    let info = {
      id_template: templateId,
      title: title,
      message_json: template,
      msg_type: typeMessage,
      channel_type: "E-mail",
      reference_design: palletOfColors,
      type: 'HTML'
    };

    const updateTemplate = await TemplateUpdate(info);

    if (updateTemplate["status"] === "success") {
      Navigate("/modelos");
    }
  };

  // Show the loading screen
  if (showLoading) {
    return <Loading open={true} />;
  }

  return (
    <>
      <HeaderPage />
      <Box
        sx={{
          position: "relative",
          width: "100%",
          minHeight: { xs: "calc(100vh - 70px)", sm: "calc(100vh - 100px)" }
        }}
      >
        <DynamicForm
          title={title}
          setTitle={setTitle}
          variablesOfTemplate={variablesOfTemplate}
          emailTitle={emailTitle}
          setEmailTitle={setEmailTitle}
          Navigate={Navigate}
          typeMessage={typeMessage}
          setTypeMessage={setTypeMessage}
          updatedHtml={updatedHtml}
          variables={variables}
          handleSubmit={updateTemplate}
          formState={formState}
          setFormState={setFormState}
          selected={selected}
          setSelected={setSelected}
          handleColorChange={setBackgroundColor}
          mapHTMLColors={palletOfColors}
          selectedColor={backgroundColor}
          isHTMLTemplate
          dropzone
          edit
        />
      </Box>
    </>
  );
};

export default EditTemplateHTML;
