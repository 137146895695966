// Description: Page to buy our services
// Updated:
// Author: Simon Franklin

// React
import React from 'react';

// Material UI Components
import { Box } from '@mui/material';

// Components and Functions for the project
import Footer from '../../components/Footer/Footer';
import usePayment from './usePayment';

const PaymentPage = () => {
    const { renderCurrentStep, currentStep, setCurrentStep } = usePayment();


    return (
        <>
            <Box component="section" sx={{ minHeight: { xs: "calc(100vh - 70px)", sm: "100vh" }, padding: { xs: "24px", sm: currentStep === 1 ? "40px 40px 15px 40px" : "60px 40px 15px 40px" }, display: "flex", flexDirection: "column", gap: "40px", justifyContent: "space-between", background: "#F4F5FF", justifyContent: "space-between" }}>

                <Box sx={{ maxWidth: "1076px", width: "100%", margin: "0 auto" }}>
                    {renderCurrentStep()}
                </Box>
                <Box sx={{ paddingLeft: { xs: "0px", sm: "40px" } }}>
                    { currentStep === 1 ? (
                        <Footer />
                    ) : (
                        <Box onClick={() => setCurrentStep((prev) => prev - 1)} sx={{ cursor: "pointer" }}>
                            <Box sx={{ pointerEvents: "none" }}>
                                <Footer />
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
        </>
    )
}

export default PaymentPage