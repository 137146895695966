import React from 'react'

const CheckIcon = ({ have }) => {
    return (
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.15" y="0.15" width="9.48765" height="9.7" rx="4.74383" fill={ have ? "#DDD4FF" : "#F8D6D3"} stroke={ have ? "#8142FC" : "#633333" } stroke-width="0.3" />
            <rect x="0.15" y="0.15" width="9.48765" height="9.7" rx="4.74383" fill={ have ? "#DDD4FF" : "#F8D6D3"} stroke={ have ? "#8142FC" : "#633333" } stroke-width="0.3" />
            <mask id="mask0_5_182" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="1" y="1" width="7" height="8">
                <rect x="1.76367" y="1.88818" width="6.04799" height="6.47376" fill={ have ? "#DDD4FF" : "#F8D6D3"} />
            </mask>
            <g mask="url(#mask0_5_182)">
                <path d="M4.16882 6.74338L2.73242 5.20586L3.09152 4.82148L4.16882 5.97462L6.48092 3.49976L6.84002 3.88414L4.16882 6.74338Z" fill={ have ? "#8142FC" : "#633333" } />
            </g>
        </svg>

    )
}

export default CheckIcon