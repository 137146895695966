// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 

Desc: This is the CSS file for the MainContent component. 
Update: 12/08/2024

*/

.MainContent {
  overflow-y: auto;  
  scrollbar-width: thin;
  scrollbar-color: #ccc #F7F7FC;
  background-color: #F7F7FC;
  max-width: calc(100vw - 300px);

  margin-left: 300px;
  transition: margin-left 0.3s ease;

  &.collapsed {
    margin-left: 100px;
    max-width: calc(100vw - 100px)
  }

}

.full-width {
  max-width: 100vw;
  margin: 0;
}


@media screen and (max-width: 960px) {
  .MainContent {
    max-width: calc(100vw - 100px);
    margin-left: 100px;
  }
}

@media screen and (max-width: 600px) {
  .MainContent {
    margin-left: 0px;
    min-width: 100vw;
    padding-top: 85px;

    &.collapsed {
      margin-left: 0px;
      max-width: 100vw;
    }
  }
}`, "",{"version":3,"sources":["webpack://./src/components/layouts/MainContent.css"],"names":[],"mappings":"AAAA;;;;;CAKC;;AAED;EACE,gBAAgB;EAChB,qBAAqB;EACrB,6BAA6B;EAC7B,yBAAyB;EACzB,8BAA8B;;EAE9B,kBAAkB;EAClB,iCAAiC;;EAEjC;IACE,kBAAkB;IAClB;EACF;;AAEF;;AAEA;EACE,gBAAgB;EAChB,SAAS;AACX;;;AAGA;EACE;IACE,8BAA8B;IAC9B,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,gBAAgB;IAChB,gBAAgB;IAChB,iBAAiB;;IAEjB;MACE,gBAAgB;MAChB,gBAAgB;IAClB;EACF;AACF","sourcesContent":["/* \n\nDesc: This is the CSS file for the MainContent component. \nUpdate: 12/08/2024\n\n*/\n\n.MainContent {\n  overflow-y: auto;  \n  scrollbar-width: thin;\n  scrollbar-color: #ccc #F7F7FC;\n  background-color: #F7F7FC;\n  max-width: calc(100vw - 300px);\n\n  margin-left: 300px;\n  transition: margin-left 0.3s ease;\n\n  &.collapsed {\n    margin-left: 100px;\n    max-width: calc(100vw - 100px)\n  }\n\n}\n\n.full-width {\n  max-width: 100vw;\n  margin: 0;\n}\n\n\n@media screen and (max-width: 960px) {\n  .MainContent {\n    max-width: calc(100vw - 100px);\n    margin-left: 100px;\n  }\n}\n\n@media screen and (max-width: 600px) {\n  .MainContent {\n    margin-left: 0px;\n    min-width: 100vw;\n    padding-top: 85px;\n\n    &.collapsed {\n      margin-left: 0px;\n      max-width: 100vw;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
