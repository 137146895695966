import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import Confirmed from '../../../../src/assets/ilustrations/Confirmed.png';
import { useNavigate } from 'react-router-dom';

const PixSuccess = () => {
    const navigate = useNavigate();

    return (
        <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
            <img src={Confirmed} style={{ width: '200px', height: '200px', marginBottom: '46px' }} alt="Pagamento confirmado" />
            <Typography sx={{ color: '#8142FC', fontSize: { xs: "24px", sm: "32px" }, fontFamily: 'Satoshi', marginBottom: '16px' }}>
                Pagamento reconhecido!
            </Typography>
            <Typography style={{ color: '#848484', fontSize: { xs: "14px", sm: "18px" }, fontFamily: 'Satoshi-Medium', maxWidth: '400px', textAlign: 'center', marginBottom: "110px" }}>
                Seu pagamento foi reconhecido com sucesso e o produto adquirido já está disponível para uso imediato.
            </Typography>

            <Button onClick={() => navigate("/painel")} variant="contained" sx={{ width: "296px", height: "48px", borderRadius: "6px", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 500 }}>
                Painel inicial
            </Button>
        </Box>
    )
}

export default PixSuccess