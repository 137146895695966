// Description: This component render a Modal when the user do something 
// Update: 12/08/2024

// React Components and DOM Elements
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Material UI Components
import { Box, styled, Button, Dialog } from '@mui/material';

// Components and functions from the project
import Titles from '../Titles/Titles';
import themeColors from '../ThemeColors/ThemeColors';
import { isAuthenticated } from '../../authMiddleware';
import StatusIcon from '../StatusLabel/StatusIcon';

// Customizating the Dialog Component
const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: "12px",
        width: "100%",
        minHeight: "500px",
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "500px !important",
    },
    '& .MuiDialog-container': {
        height: "100vh",
        width: "100vw",
        position: "fixed",
        top: "0",
        left: "0",
        display: "block",
    },
}));

// Render Your Component
function ModalR({ isOpen, onClose, onConfirm, title, subTitle, confirmText, cancelText, iconColor, emoji, iconButton , iconButtonClose}) {
    // Authentication Context Functions
    const { authenticated } = isAuthenticated();
    const navigate = useNavigate();

    // State variables
    const [userInfo, setUserInfo] = useState({});

    // Effect to fetch the user info
    useEffect(() => {
        if (authenticated) {
            const { decryptedData } = isAuthenticated();
            setUserInfo(decryptedData);
        } else {
            navigate("/login");
        }
    }, []);

    const closeModal = () => {
        onClose(false);
    };

    return (
        <CustomDialog
            open={isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={false}
            onBackdropClick={closeModal}
        >
            <Box padding={"50px"} paddingTop={"75px"} sx={{ textAlign: "center", backgroundColor: "#fff" }}>
                <Box
                    display={"flex"}
                    sx={{ justifyContent: "center", marginBottom: "10px", gap: "10px" }}
                >
                    <StatusIcon color={iconColor} status={emoji} />
                    <StatusIcon color={iconColor} status={emoji} />
                    <StatusIcon color={iconColor} status={emoji} />
                </Box>

                <Box display={"flex"} sx={{ flexDirection: "column", gap: "10px", marginBottom: "20px", marginTop: "20px" }}>
                    <Titles font={"Satoshi"} children={title} color={themeColors.purple600} type={"H3"} />

                    <Titles font={"Satoshi"} children={subTitle} color={themeColors.gray50} type={"P1"} />
                </Box>

                <Box display={"flex"} sx={{ flexDirection: "column", gap: "5px" }}>
                    <Button sx={{
                        backgroundColor: "transparent",
                        border: `2px solid ${themeColors.purple400}`,
                        color: themeColors.purple500,
                        padding: "12px 20px",
                        borderRadius: "6px",
                        width: "100%",
                    }} onClick={closeModal}
                    startIcon={iconButtonClose}
                    >
                        {cancelText}
                    </Button>
                    {confirmText && (
                        <Button sx={{
                            backgroundColor: themeColors.purple500,
                            color: "white",
                            padding: "12px 20px",
                            borderRadius: "6px",
                            border: "none",
                            width: "100%",
                            '&:hover': {
                                backgroundColor: themeColors.purple400
                            },
                        }} onClick={onConfirm}
                        startIcon={iconButton}
                        >
                            {confirmText}
                        </Button>
                    )}
                </Box>
            </Box>
        </CustomDialog>
    );
}

export default ModalR