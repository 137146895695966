// Desc: Component costum card used in project
// Update:  12/08/2024

// React Components and DOM Elements
import React from 'react';

// Material-UI Components
import { Grid, Card, CardContent, Typography, CircularProgress } from '@mui/material';

// Render component
const DashboardCard = ({
  title = 'Default Title',  
  icon, 
  loading = false,  
  quantity = null,
  formatNumber = (num) => num, 
  onClickOption = console.log('Card clicked'),
  backgroundColor = '#ffffff',
  titleColor = '#000000',
  valueColor = '#000000', 
  onClick = null, 
}) => {

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card
      onClick={onClick || onClickOption}
      sx={{
        display: 'flex',
        minHeight: '140px',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
        borderRadius: '2px',
        backgroundColor: '#fff',
        padding: '10px',
        background: backgroundColor || '#fff',
        ":hover": {
          cursor: typeof onClickOption === 'function' ? 'pointer' : 'default',
        }
      }}>
        <CardContent sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          p: 2,
        }}>
          {loading ? (
            <CircularProgress color="secondary" size={40} />
          ) : (
            <>
              <Typography variant="subtitle2" sx={{ alignSelf: 'flex-start', color: titleColor }}>
                {title}
              </Typography>
              <Typography variant="h5" sx={{ fontWeight: 'bold', alignSelf: 'flex-start', color: valueColor }}>
                {quantity !== undefined ? formatNumber(quantity) : '-'}
              </Typography>
            </>
          )}
        </CardContent>
        <div sx={{
          alignSelf: 'center',
          px: 2,
          marginRight: '10px',
        }}>
          {!loading && !icon &&
            <div className="spaceicon"></div>
          }
        </div>
      </Card>
    </Grid>
  );
};

export default DashboardCard;