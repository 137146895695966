import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { insertMaskForCep, insertMaskForPhoneNumber } from '../../../utils/utils';
import Resume from "../Resume";
import CheckIcon from '../StepCheckIcon';
import themeColors from '../../../components/ThemeColors/ThemeColors';
import PIXIcon from '../PIXIcon';
import StatusIcon from '../../../components/StatusLabel/StatusIcon';
import Boleto from '../Boleto.png';
import Lock from '../lock.png';
import PixModal from '../PixModal';

const Step3 = ({
    name,
    street,
    city,
    cep,
    product,
    months,
    setCurrentStep,
    surname,
    cel,
    phone,
    economy,
    subtotal,
    qrCode,
    pixCode
}) => {
    const [openPix, setOpenPix] = useState(false);
    const [openBankSlip, setOpenBankSlip] = useState(false);

    const [acceptedPix, setAcceptedPix] = useState(false);
    const [acceptedBankSlip, setAcceptedBankSlip] = useState(false);

    const [openPixModal, setOpenPixModal] = useState(false);

    return (
        <>
            <PixModal open={openPixModal} onClose={() => setOpenPixModal(false)} pixkey={pixCode} qrCode={qrCode} />
            <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
                <Box
                    component={"form"}
                    sx={{ display: "flex", gap: "28px", flexDirection: { xs: "column", sm: "row" } }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "30px",
                            flex: "1"
                        }}
                    >
                        <Box sx={{ background: "#FFF", borderRadius: "16px", flexDirection: "column", gap: "24px", justifyContent: "space-between", padding: "30px", display: "flex" }}>
                            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                <CheckIcon />
                                <Typography
                                    sx={{
                                        fontSize: { xs: "20px", sm: "24px" },
                                        color: "#38216A",
                                        fontWeight: 600,
                                    }}
                                >
                                    Endereço de faturamento
                                </Typography>
                            </Box>

                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    <Typography sx={{ color: "#38216A", fontWeight: 500, fontSize: { xs: "14px", sm: "16px" } }}>
                                        {name} {surname}
                                    </Typography>
                                    <Typography sx={{ color: "#38216A", fontWeight: 500, fontSize: { xs: "14px", sm: "16px" } }}>
                                        {insertMaskForPhoneNumber(phone)} / {insertMaskForPhoneNumber(cel)}
                                    </Typography>
                                    <Typography sx={{ color: "#38216A", fontWeight: 500, fontSize: { xs: "14px", sm: "16px" } }}>
                                        {street}, {insertMaskForCep(cep)} - {city}
                                    </Typography>
                                </Box>
                                <Typography onClick={() => setCurrentStep(2)} sx={{ color: "#8033F4", fontWeight: 600, fontSize: { xs: "14px", sm: "16px" }, alignSelf: "end", cursor: "pointer" }}>
                                    Editar
                                </Typography>
                            </Box>
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "column", gap: "32px", background: "#FFF", borderRadius: "16px", padding: "30px", height: "100%" }}>
                            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                <Box
                                    sx={{
                                        fontSize: { xs: "14px", sm: "18px" },
                                        color: themeColors.purple500,
                                        fontWeight: 600,
                                        border: "1px solid #38216A",
                                        width: "36px",
                                        height: "36px",
                                        borderRadius: "50%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    2
                                </Box>
                                <Typography
                                    sx={{
                                        fontSize: { xs: "20px", sm: "24px" },
                                        color: "#38216A",
                                        fontWeight: 600,
                                    }}
                                >
                                    Pagamento
                                </Typography>
                            </Box>

                            <Box sx={{ display: "flex", flexDirection: "column", gap: "26px" }}>
                                <Box sx={{ border: "1px solid #B7B7B7", borderRadius: "12px", display: "flex", flexDirection: "column", padding: "20px 24px", gap: "30px" }}>
                                    <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                                        <Typography sx={{ color: "#38216A", fontWeight: 500, fontSize: { xs: "14px", sm: "16px" } }}>
                                            PIX via QR Code
                                        </Typography>
                                        <Box sx={{ display: "flex", alignItems: "center", gap: "35px" }}>
                                            <PIXIcon />
                                            <Box onClick={() => setOpenPix((prev) => !prev)} sx={{ cursor: "pointer", display: "inline-block", transform: openPix ? "rotate(180deg)" : "none" }}>
                                                <StatusIcon status={'ArrowBottom'} width={'24px'} height={'24px'} color={'#52525B'} />
                                            </Box>
                                        </Box>
                                    </Box>
                                    {
                                        openPix ? (
                                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                                <Box sx={{ display: "flex", gap: "19px", alignItems: "start", marginBottom: "37px" }}>
                                                    <Box onClick={() => setAcceptedPix((prev) => !prev)} sx={{ border: "1px solid #7B25FF", minWidth: "17px", height: "17px", borderRadius: "2px", background: acceptedPix ? "#7B25FF" : "none", cursor: "pointer" }} />

                                                    <Typography sx={{ color: "#38216A", fontSize: { xs: "13px", sm: "16px" }, }}>
                                                        Ao finalizar a compra, você concorda com nossos <a href='https://123zap.com.br/termos-de-uso/' target='_blank' style={{ textDecoration: "underline", fontWeight: "500" }}>Termos de Serviço</a> e confirma que leu nossa <a href='https://123zap.com.br/politica-de-privacidade/' target='_blank' style={{ textDecoration: "underline", fontWeight: "500" }}>Política de Privacidade</a>. Você pode cancelar pagamentos recorrentes a qualquer momento.
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: "flex", alignItems: "center", gap: "6px", marginBottom: "26px" }}>
                                                    <Box component="img" src={Lock} sx={{ width: "16px", height: "16px", objectFit: "contain" }} />
                                                    <Typography sx={{ fontSize: { xs: "14px", sm: "16px" }, color: "#000" }} children="Pagamentos Criptografados e Seguros" />
                                                </Box>
                                                <Button onClick={() => acceptedPix && setOpenPixModal(true)} sx={{ color: acceptedPix ? "#FFF" : "#52525B", background: acceptedPix ? themeColors.purple500 : "#E5E5E5", borderRadius: "6px", padding: "13px 30px", maxWidth: "211.75px", fontWeight: 600, pointerEvents: acceptedPix ? "auto" : "none", '&:hover': { opacity: "100%", background: acceptedPix ? themeColors.purple600 : "#E5E5E5" } }}>
                                                    Gerar Pagamento
                                                </Button>
                                            </Box>
                                        ) : (
                                            <></>
                                        )
                                    }
                                </Box>
                                <Box sx={{ border: "1px solid #B7B7B7", borderRadius: "12px", display: "flex", flexDirection: "column", padding: "20px 24px", gap: "30px" }}>
                                    <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                                        <Typography sx={{ color: "#38216A", fontWeight: 500, fontSize: { xs: "14px", sm: "16px" } }}>
                                            Boleto
                                        </Typography>
                                        <Box sx={{ display: "flex", alignItems: "center", gap: "35px" }}>
                                            <Box src={Boleto} component="img" sx={{ width: "54px", height: "24px", objectFit: "contain" }} alt="Boleto" />
                                            <Box onClick={() => setOpenBankSlip((prev) => !prev)} sx={{ cursor: "pointer", display: "inline-block", transform: openBankSlip ? "rotate(180deg)" : "none" }}>
                                                <StatusIcon status={'ArrowBottom'} width={'24px'} height={'24px'} color={'#52525B'} />
                                            </Box>
                                        </Box>
                                    </Box>
                                    {
                                        openBankSlip ? (
                                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                                <Box sx={{ display: "flex", gap: "19px", alignItems: "start", marginBottom: "37px" }}>
                                                    <Box onClick={() => setAcceptedBankSlip((prev) => !prev)} sx={{ border: "1px solid #7B25FF", minWidth: "17px", height: "17px", borderRadius: "2px", background: acceptedBankSlip ? "#7B25FF" : "none", cursor: "pointer" }} />

                                                    <Typography sx={{ color: "#38216A", fontSize: { xs: "13px", sm: "16px" }, }}>
                                                        Ao finalizar a compra, você concorda com nossos <a href='https://123zap.com.br/termos-de-uso/' target='_blank' style={{ textDecoration: "underline", fontWeight: "500" }}>Termos de Serviço</a> e confirma que leu nossa <a href='https://123zap.com.br/politica-de-privacidade/' target='_blank' style={{ textDecoration: "underline", fontWeight: "500" }}>Política de Privacidade</a>. Você pode cancelar pagamentos recorrentes a qualquer momento.
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: "flex", alignItems: "center", gap: "6px", marginBottom: "26px" }}>
                                                    <Box component="img" src={Lock} sx={{ width: "16px", height: "16px", objectFit: "contain" }} />
                                                    <Typography sx={{ fontSize: { xs: "14px", sm: "16px" }, color: "#000" }} children="Pagamentos Criptografados e Seguros" />
                                                </Box>
                                                <Button onClick={() => acceptedBankSlip && {}} sx={{ color: acceptedBankSlip ? "#FFF" : "#52525B", background: acceptedBankSlip ? themeColors.purple500 : "#E5E5E5", borderRadius: "6px", padding: "13px 30px", maxWidth: "211.75px", fontWeight: 600, pointerEvents: acceptedBankSlip ? "auto" : "none", '&:hover': { opacity: "100%", background: acceptedBankSlip ? themeColors.purple600 : "#E5E5E5" } }}>
                                                    Gerar Pagamento
                                                </Button>
                                            </Box>
                                        ) : (
                                            <></>
                                        )
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <Resume economy={economy} months={months} product={product} subtotal={subtotal} />
                </Box>
            </Box>
        </>
    )
}

export default Step3