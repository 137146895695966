// This is a custom hook that returns the current step of the self register form
// Updated at: 20/12/2024

// React
import React, { useEffect, useState } from "react";

import { cpf, cnpj } from 'cpf-cnpj-validator';

// Components and functions for the project
import Step1 from "./Steps/Step1.js";
import Step2 from "./Steps/Step2.js";
import Step3 from "./Steps/Step3.js";
import Step4 from "./Steps/Step4.js";
import Step5 from "./Steps/Step5.js";
import axios from "axios";

const useSelfRegister = () => {
  // States for the function
  const [currentStep, setCurrentStep] = useState(1);
  const [message, setMessage] = useState("");
  const [canMoveForward, setCanMoveForward] = useState(false);
  const [usuario, setUsuario] = useState("");

  // Step 1
  const [selected, setSelected] = useState("");
  // Step 2
  const [username, setUsername] = useState("");
  const [cpfOrCnpj, setCpfOrCnpj] = useState("");
  const [contact, setContact] = useState("");
  // Step 3
  const [cep, setCep] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [number, setNumber] = useState("");
  // Step 4
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmingEmail, setConfirmingEmail] = useState(false);
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [canVerify, setCanVerify] = useState(true);
  // Step 5
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [paymentForm, setPaymentForm] = useState("");
  const [termsOfUse, setTermsOfUse] = useState(false);

  const [loading, setLoading] = useState(false);

  // Function to get the confirm email code
  const getVerifyCode = async () => {
    setLoading(true);
  
    try {
      if (email && password && confirmPassword && password === confirmPassword) {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/send-confirm-email`,
          { email, nome: username }
        );
  
        if (response.status === 200) {
          setConfirmingEmail(true);
          setCanVerify(false);
  
          setTimeout(() => {
            setCanVerify(true);
          }, 40000);
        }
      } else if (password !== confirmPassword) {
        setMessage("As senhas não coincidem");
      } else {
        setMessage("Preencha todos os campos");
      }
    } catch (error) {
      console.error("Ocorreu um erro inesperado:", error);
      setMessage("Erro ao enviar o código de verificação.");
    } finally {
      setLoading(false);
    }
  };
  
  // Function to send the confirmation code
  const sendConfirmationCode = async () => {
    setLoading(true);
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/verify-confirm-email`,
        { email, code: confirmationCode }
      );
  
      if (response.status === 200) {
        setEmailConfirmed(true);
        setConfirmingEmail(false);
        setCurrentStep(5);
      } else {
        setMessage("Seu email ainda não foi confirmado");
      }
    } catch (error) {
      console.error("Ocorreu um erro inesperado:", error);
      setMessage("Erro ao verificar o código de confirmação.");
    } finally {
      setLoading(false);
    }
  };

  // Functions to change the step
  const nextStep = () => {
    if (canMoveForward) {
      setCurrentStep((prev) => (prev < 5 ? prev + 1 : 5));
      setMessage("");
    } else {

      if (currentStep === 1 && selected === "") {
        setMessage("Selecione um tipo de pessoa");
      } else if (currentStep === 2 && username === "") {
        setMessage("Por favor, preencha todos os campos.");
      } else if (currentStep === 2 && contact === "") {
        setMessage("Por favor, preencha todos os campos.");
      }
      else if (currentStep === 2 && cpfOrCnpj === "") {
        setMessage("Por favor, preencha todos os campos.");
      }
      else if (currentStep === 2 && (!cpf.isValid(cpfOrCnpj) && !cnpj.isValid(cpfOrCnpj))) {
        setMessage("CPF ou CNPJ inválido");
      } else if (currentStep === 3 && cep === "") {
        setMessage("Por favor, preencha todos os campos.");
      } else if (currentStep === 3 && state === "") {
        setMessage("Por favor, preencha todos os campos.");
      } else if (currentStep === 3 && city === "") {
        setMessage("Por favor, preencha todos os campos.");
      } else if (currentStep === 3 && street === "") {
        setMessage("Por favor, preencha todos os campos.");
      } else if (currentStep === 3 && number === "") {
        setMessage("Por favor, preencha todos os campos.");
      } else if (currentStep === 4 && email === "") {
        setMessage("Por favor, preencha todos os campos.");
      } else if (currentStep === 4 && password === "") {
        setMessage("Por favor, preencha todos os campos.");
      } else if (currentStep === 4 && confirmPassword === "") {
        setMessage("Por favor, preencha todos os campos.");
      } else if (currentStep === 4 && password !== confirmPassword) {
        setMessage("As senhas não coincidem.");
      } 
    }
  };


  const prevStep = () => {
    setCurrentStep((prev) => (prev > 1 ? prev - 1 : 1));
    setMessage("");
  };

  // Function to validade if the user can move forward in the multistep form
  const validateStep = (step) => {
    switch (step) {
      case 1:
        return selected !== "";
      case 2:
        return username !== "" && cpfOrCnpj !== "" && contact !== "" && (cpf.isValid(cpfOrCnpj) || cnpj.isValid(cpfOrCnpj));
      case 3:
        return cep !== "" && state !== "" && street !== "" && number !== "";
      case 4:
        return email !== "" && password !== "" && confirmPassword !== "" && password === confirmPassword && usuario !== "" && emailConfirmed === true;
      case 5:
        return selected !== null && paymentForm !== null && termsOfUse !== null;
      default:
        return false;
    }
  };

  // Effect to check the validation of the current step
  useEffect(() => {
    setCanMoveForward(validateStep(currentStep));
  }, [currentStep, selected, username, cpfOrCnpj, contact, cep, state, street, number, email, password, confirmPassword])

  // Function to render the current Step
  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return <Step1 message={message} selected={selected} setSelected={setSelected} />;
      case 2:
        return <Step2 message={message} selected={selected} contact={contact} cpf={cpfOrCnpj} setContact={setContact} setUsername={setUsername} setCpf={setCpfOrCnpj} username={username} />;
      case 3:
        return <Step3 message={message} cep={cep} number={number} setCep={setCep} setNumber={setNumber} state={state} setState={setState} city={city} setCity={setCity} setStreet={setStreet} street={street} />;
      case 4:
        return <Step4 message={message} email={email} setEmail={setEmail} confirmPassword={confirmPassword} setConfirmPassword={setConfirmPassword} password={password} setPassword={setPassword} setShowConfirmPassword={setShowConfirmPassword} showConfirmPassword={showConfirmPassword} setShowPassword={setShowPassword} showPassword={showPassword} usuario={usuario} setUsuario={setUsuario} confirmingEmail={confirmingEmail} confirmEmailCode={confirmationCode} setConfirmEmailCode={setConfirmationCode} />;
      case 5:
        return <Step5 message={message} selectedPlan={selectedPlan} paymentForm={paymentForm} setPaymentForm={setPaymentForm} setSelectedPlan={setSelectedPlan} termsOfUse={termsOfUse} setTermsOfUse={setTermsOfUse} />;
      default:
        return null;
    }
  };

  const getFormData = () => {
    return {
      selected,
      username,
      cpfOrCnpj,
      contact,
      cep,
      state,
      city,
      street,
      number,
      email,
      password,
      confirmPassword,
      selectedPlan,
      paymentForm,
      usuario,
      termsOfUse
    };
  };

  return {
    currentStep,
    nextStep,
    prevStep,
    renderCurrentStep,
    message,
    setMessage,
    getFormData,
    setConfirmingEmail,
    getVerifyCode,
    confirmingEmail,
    sendConfirmationCode,
    loading,
    termsOfUse
  };

};

export default useSelfRegister;