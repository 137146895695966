// Desc: This page is responsible for showing the options of channels that the user can use to send the templates
// Update: 20/01/2024

import React, { useEffect, useState } from 'react';
import Titles from '../../components/Titles/Titles';
import CardList from '../../components/CardList/CardList';
import HeaderPage from '../../components/HeaderPage/HeaderPage';
import Box from '@mui/material/Box';
import Fast from '../../assets/images/Fast.png';
import Select from '../../assets/images/Select.png';
import Advanced from '../../assets/images/Advanced.png';
// Colors of the theme
import Footer from '../../components/Footer/Footer';

import Loading from '../../components/Loading/Loading';
import { useNavigate } from 'react-router-dom';

const PageOptionsCampaign = (props) => {
    const [showLoading, setShowLoading] = useState(true);
    const navigate = useNavigate();

    const data = [
        {
            image: Fast,
            title: 'Modo Rápido',
            subtitle: 'Crie campanhas de modo rápido e fácil, ideal para quem deseja enviar mensagens de texto ou imagens para seus clientes de forma rápida e segura.',
            onClick: () => navigate('/campanhas/adicionar/modo-rapido'),
            pago: true, icon: "FastForward", corFundo: "#DDD4FF", titleColor: "#8033F4",
        },
        {
            image: Select,
            title: 'Modo Seleção',
            subtitle: 'Crie campanhas selecionando e filtrando exatamente os clientes que deseja atingir, ideal para disparos pequenos e segmentados.',
            onClick: () => navigate('/campanhas/adicionar/modo-seleção'),
            pago: true, icon: "Check", corFundo: "#DDD4FF", titleColor: "#8033F4",
        },
        {
            image: Advanced,
            title: 'Modo Personalizado',
            subtitle: 'Crie campanhas com configurações personalizadas, ideal para campanhas complexas e com regras específicas. por exemplo, campanhas de faturamento.',
            onClick: () => navigate('/campanhas/adicionar/modo-personalizado'),
            pago: true, icon: "Pen", corFundo: "#DDD4FF", titleColor: "#8033F4",
        },
        {
            image: Advanced,
            title: 'Modo Ajuda',
            subtitle: 'Melhor opção para você que é novo na plataforma, vamos criar uma campanha customizada juntos para enviar aos seus clientes.',
            onClick: () => navigate('/campanhas/adicionar/modo-ajuda'),
            pago: true, icon: "Help", corFundo: "#DDD4FF", titleColor: "#8033F4",
        },


    ];

    useEffect(() => {
        // Simulate a API call
        setTimeout(() => setShowLoading(false), 1000);
    }, []);

    // Show the loading screen
    if (showLoading) {
        return <Loading open={true} />;
    }

    return (
        <>

            <Box style={{ position: 'relative', minHeight: '100vh' }}>
                <HeaderPage title="Modelos" />

                <Box sx={{ borderRadius: '2px', overflow: 'hidden', padding: { xs: '20px', sm: '40px' }, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: { xs: 'calc(100vh - 75px)', sm: 'calc(100vh - 85px)' } }}>
                    <Box>
                        <Titles styles={{ fontSize: { xs: "20px", sm: "24px" } }} children={"Como você deseja criar sua campanha?"} color={"#848484"} />
                        <CardList data={data} type='campaign' />
                    </Box>

                    <div style={{ padding: "40px 0 0 0" }}>
                        <Footer />
                    </div>
                </Box>
            </Box>
        </>
    );
};

export default PageOptionsCampaign;