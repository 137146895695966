// Description: Component that allow the user to see his notifications
// Updated: 05/12/2024
// Author: Simon Franklin

// React
import React, { useState } from 'react'

// Material UI Components
import { Box, Pagination, Typography } from '@mui/material'
import NotifyComponent from './NotifyComponent';
import { ReadN } from '../../api/backend/Dashboard/ReadN';
import YouDontHaveNotifications from '../../pages/Dashboard/YouDontHaveNotifications';

// Components and functions for the project

// Render The Component
const Notifications = ({ open, currentNotifications, setCurrentNotifications, allNotifications, newNotifications, setAllNotifications, setNewNotifications }) => {
    const [showAll, setShowAll] = useState(newNotifications.length > 0 ? false : true);
    const [currentPage, setCurrentPage] = useState(1);
    const [expandedNotification, setExpandedNotification] = useState(false);
    const notificationsPerPage = 8;
    const startIndex = notificationsPerPage * (currentPage - 1);
    const endIndex = notificationsPerPage * currentPage;

    const handleChangePage = (event, value) => {
        setCurrentPage(value);
    };

    const updateNotificationStatus = async (notificationId) => {
        try {
            const info = { 'notification_id': notificationId };
            const jsonData = await ReadN(info);

            if (jsonData['message'] === 'Notification read successfully') {
                setAllNotifications((prev) => prev.filter((notification) => notification.id !== notificationId));
                setNewNotifications((prev) => prev.filter((notification) => notification.id !== notificationId));
                
                setCurrentNotifications((prev) =>
                    prev.map((notification) =>
                        notification.id === notificationId
                            ? { ...notification, read: true }
                            : notification
                    )
                );
            }
        } catch (error) {
            console.error('Erro ao atualizar o status da notificação:', error);
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            height: 'calc(100vh - 84px)',
            position: 'fixed',
            top: open ? '85px' : '-100%',
            left: '0',
            transition: '.4s ease-in-out',
            zIndex: '10',
            width: '100vw',
            flexDirection: 'column',
            gap: '0',
            background: '#F9F9F9',
            paddingTop: '2px'
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
            }}>
                <Box sx={{
                    display: 'flex',
                    padding: '15px 43px',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid #D5D5D5'
                    
                }}>
                    <Typography children='Todas' sx={{ color: showAll ? '#A986FF' : '#848484', fontWeight: '600', fontSize: '15px' }} onClick={() => (setShowAll(true), setCurrentNotifications(allNotifications))} />
                    <Typography children='Não Lidas' sx={{ display: newNotifications.length > 0 ? 'flex' : 'none', color: showAll ? '#848484' : '#A986FF', fontWeight: '600', fontSize: '15px' }} onClick={() => (setShowAll(false), setCurrentNotifications(newNotifications))} />
                </Box>
            </Box>
            <Box sx={{
                display: 'flex',
                flex: '1',
                flexDirection: 'column',
                maxHeight: 'calc(100vh - 84px)',
                position: 'relative',
                overflow: 'hidden'
            }}>
                {
                    currentNotifications.length > 0 ? (
                        currentNotifications.slice(startIndex, endIndex).map((notification) => (
                            <NotifyComponent key={notification.id} notification={notification} updateNotificationStatus={updateNotificationStatus} setExpandedNotification={setExpandedNotification} />
                        ))
                    ) : (
                        <Box sx={{ height: '100%', background: '#fff' , display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <YouDontHaveNotifications />
                        </Box>
                    )
                }
            </Box>
            {currentNotifications.length > notificationsPerPage && !expandedNotification && (
                <Box
                    sx={{ width: '100%', height: '100%', maxHeight: '120px', alignItems: 'center', display: 'flex', justifyContent: 'center', alignSelf: 'flex-end' }}
                >
                    <Pagination
                        count={Math.ceil(currentNotifications.length / notificationsPerPage)}
                        page={currentPage}
                        onChange={handleChangePage}
                        variant='outlined'
                        shape="rounded"
                    />
                </Box>
            )}
        </Box>
    )
}

export default Notifications