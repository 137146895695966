// Description: Component that return a Help Add
// Update:  12/08/2024

// React Components and DOM Elements
import React from 'react';

// Material UI Components
import { Box } from '@mui/system';
import { Grid } from '@mui/material';

// Components and functions from the project
import ThemeColors from '../ThemeColors/ThemeColors';
import Titles from '../Titles/Titles';

// Render Your Component
function HelpAdd({ Steps, Title }) {

    // Function to get the number
    function returnNumber2char(number) {
        if (number < 10) {
            return "0" + number;
        }
        return number;
    }

    // Return Your Component
    return (

        <Box>

            <Grid container spacing={0}>
                {Title && (<Grid item xs={12} style={{ marginBottom: '20px' }} color={ThemeColors.gray40}>

                    <Titles type={"H3"} children={Title} />
                </Grid>)}

                {Steps && Steps.map((step, index) => (
                    <Grid item xs={12} key={index} style={{ 'background': '#FFF', 'padding': '10px', 'borderRadius': '12px', 'marginBottom': '10px' }}>

                        <div style={{ 'display': 'flex', 'justifyContent': 'left', 'alignItems': 'center' }}>
                            <div style={{ 'background': ThemeColors.purple200, 'color': ThemeColors.purple600, 'padding': '5px', 'borderRadius': '6px', minWidth: '40px', minHeight: '40px', 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center', fontWeight: 'bold', fontSize: '16px', marginRight: '10px', fontFamily: 'Satoshi-Black' }}>
                                {returnNumber2char(index + 1)}
                            </div>
                            <Titles type={"P1"} children={step.desc} />
                        </div>
                    </Grid>
                ))}
            </Grid>
        </Box >

    );
}

export default HelpAdd;