// Description: This component render an Alert when the user do something
// Update: 12/08/2024

// React Components and DOM Elements
import React, { useEffect, useState } from 'react'

// Material UI Components
import { Box } from '@mui/material';

// Components and functions from the project
import themeColors from '../ThemeColors/ThemeColors'
import StatusIcon from '../StatusLabel/StatusIcon'

// Render Your Component
const CustomAlert = ({ type, message, right }) => {
  // State variables
  const [isMainContentCollapsed, setIsMainContentCollapsed] = useState(false);
  
  // Effect to check if the sidebar is collapsed or not
  useEffect(() => {
    const mainContent = document.querySelector('.MainContent');
    if (mainContent) {
      setIsMainContentCollapsed(mainContent.classList.contains('collapsed'));
    }
  }, []);

  return (
    <Box style={{
      background: type === "Alert" ? themeColors.yellow01 : type === "Success" ? themeColors.green01 : type === "Error" ? themeColors.red01 : "#fff",
      color: type === "Alert" ? themeColors.yellow03 : type === "Success" ? themeColors.green03 : type === "Error" ? themeColors.red03 : "#000",
      padding: "10px",
      borderRadius: "8px",
      fontFamily: "Satoshi",
      maxWidth: "100vw",
      display: "flex",
      gap: "10px",
      alignItems: "center",
      left: right === true ? "unset" : isMainContentCollapsed ? '100px' : '320px',
      right: right === true ? "40px" : "unset",
      position: "fixed",
      bottom: "20px"
    }}>
      <StatusIcon status={'Alert'} sx={{ marginLeft: '10px' }} width={"16px"} height={"16px"} color={type === "Alert" ? themeColors.yellow03 : type === "Success" ? themeColors.green03 : type === "Error" ? themeColors.red03 : "#000"} />
      {message}
    </Box>
  )
}

export default CustomAlert