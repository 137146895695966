import React from 'react'

const PIXIcon = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.8516 14.3179C12.1156 14.0536 12.5703 14.0536 12.8343 14.3179L16.599 18.087C17.2933 18.7821 18.2174 19.1639 19.1952 19.1639H19.9335L15.186 23.917C13.7046 25.361 11.2991 25.361 9.81763 23.917L5.0506 19.1492H5.5053C6.48316 19.1492 7.40723 18.7674 8.1015 18.0723L11.8516 14.3179ZM12.8343 10.7152C12.5214 10.9844 12.1205 10.9893 11.8516 10.7152L8.1015 6.96069C7.40723 6.22155 6.48316 5.88379 5.5053 5.88379H5.0506L9.81274 1.11606C11.2991 -0.37202 13.7046 -0.37202 15.186 1.11606L19.9384 5.86911H19.1952C18.2174 5.86911 17.2933 6.25092 16.599 6.94601L12.8343 10.7152ZM5.5053 6.98517C6.18002 6.98517 6.80096 7.25929 7.31922 7.739L11.0693 11.4935C11.4213 11.8019 11.8809 12.0221 12.3454 12.0221C12.805 12.0221 13.2646 11.8019 13.6166 11.4935L17.3813 7.72431C17.8605 7.2495 18.5205 6.97538 19.1952 6.97538H21.0385L23.8889 9.82916C25.3704 11.3124 25.3704 13.7207 23.8889 15.2039L21.0385 18.0577H19.1952C18.5205 18.0577 17.8605 17.7835 17.3813 17.3038L13.6166 13.5347C12.937 12.8543 11.7489 12.8543 11.0693 13.5396L7.31922 17.2891C6.80096 17.7689 6.18002 18.043 5.5053 18.043H3.95052L1.11475 15.2039C-0.371584 13.7207 -0.371584 11.3124 1.11475 9.82916L3.95052 6.98517H5.5053Z" fill="#32BCAD" />
        </svg>
    )
}

export default PIXIcon