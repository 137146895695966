// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*

Desc: This file contains the styles for the phone component.
updated: 12/08/2024

*/

.contain {
  position: relative;
}

.phonebody-external {
  margin: 0 auto;
  position: relative;
  background: rgb(44, 55, 61);
  width: 43vh;
  height: 85vh;
  border-radius: 6vh;
  padding: 1.5vh;
  border: 0.3vh solid rgb(44, 55, 61);
  zoom: 0.9;
  overflow: hidden;
}

.phonebody-external::before {
  content: '';
  position: absolute;
  top: -10px; 
  left: 50%;
  transform: translateX(-50%);
  width: 170px;
  height: 40px;
  background: rgb(44, 55, 61);
  border-radius: 0 0 50px 50px;
  z-index: 2;
}

.phonebody-external::after {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 154px; 
  height: 40px; 
  background: rgb(44, 55, 61);
  border-radius: 0 0 40px 40px; 
  z-index: 3;
}

.phonebody-internal {
  width: 100%;
  height: 100%;
  background: '#263238';
  background-size: 100% 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/WhatsAppScreen/Phone.css"],"names":[],"mappings":"AAAA;;;;;CAKC;;AAED;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,2BAA2B;EAC3B,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,cAAc;EACd,mCAAmC;EACnC,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,2BAA2B;EAC3B,YAAY;EACZ,YAAY;EACZ,2BAA2B;EAC3B,4BAA4B;EAC5B,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,2BAA2B;EAC3B,YAAY;EACZ,YAAY;EACZ,2BAA2B;EAC3B,4BAA4B;EAC5B,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,qBAAqB;EACrB,0BAA0B;AAC5B","sourcesContent":["/*\n\nDesc: This file contains the styles for the phone component.\nupdated: 12/08/2024\n\n*/\n\n.contain {\n  position: relative;\n}\n\n.phonebody-external {\n  margin: 0 auto;\n  position: relative;\n  background: rgb(44, 55, 61);\n  width: 43vh;\n  height: 85vh;\n  border-radius: 6vh;\n  padding: 1.5vh;\n  border: 0.3vh solid rgb(44, 55, 61);\n  zoom: 0.9;\n  overflow: hidden;\n}\n\n.phonebody-external::before {\n  content: '';\n  position: absolute;\n  top: -10px; \n  left: 50%;\n  transform: translateX(-50%);\n  width: 170px;\n  height: 40px;\n  background: rgb(44, 55, 61);\n  border-radius: 0 0 50px 50px;\n  z-index: 2;\n}\n\n.phonebody-external::after {\n  content: '';\n  position: absolute;\n  top: 0;\n  left: 50%;\n  transform: translateX(-50%);\n  width: 154px; \n  height: 40px; \n  background: rgb(44, 55, 61);\n  border-radius: 0 0 40px 40px; \n  z-index: 3;\n}\n\n.phonebody-internal {\n  width: 100%;\n  height: 100%;\n  background: '#263238';\n  background-size: 100% 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
