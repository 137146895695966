import React from 'react'

const CheckIcon = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.15" y="0.15" width="39.7" height="39.7" rx="19.85" fill="none" stroke="#34A853" stroke-width="0.3" />
            <mask id="mask0_5_182" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="7" y="7" width="25" height="27">
                <rect x="7.70703" y="8.05371" width="23.7168" height="24.895" fill="#D9D9D9" stroke="#34A853" />
            </mask>
            <g mask="url(#mask0_5_182)">
                <path d="M11.1852 20.8234L12.6355 19.304L17.0292 23.9071L17.0382 23.9166L17.0473 23.9071L26.4873 14.0171L27.9375 15.5365L17.0382 26.9554L11.1852 20.8234Z" fill="#34A853" stroke="#34A853" stroke-width="0.025" />
            </g>
        </svg>

    )
}

export default CheckIcon