// Description: Component that shows the user that they don't have notifications
// Updated: 05/12/2024
// Author: Simon Franklin

// React
import React from 'react'

// Material UI Components
import { Box } from '@mui/material'

// Components and functions for the project
import Notify from '../../assets/ilustrations/Notify.gif'
import Titles from '../../components/Titles/Titles'

// Render the component
const YouDontHaveNotifications = () => {
    return (
        <Box display="flex" sx={{ justifyContent: "center", alignItems: "center", width: "100%", height: "90%" }}>
            <Box display="flex" sx={{ flexDirection: "column", alignItems: "center", gap: "20px", width: "80%" }}>
                {/* Text Titles */}
                <Box display="flex" sx={{ flexDirection: "column", gap: "10px", textAlign: "center" }}>
                    <Titles bold children={"Você ainda não possui notificações"} color={"#52525B"} styles={{ fontSize: { xs: '18px', sm: '24px' } }} />
                    <Titles children={"Fique atento às novidades!"} color={"#52525B"} styles={{ fontSize: { xs: '16px', sm: '16px' } }} />
                </Box>
                {/* Image */}
                <Box component='img' src={Notify} alt="Você não tem notificações" sx={{ width: { xs: '100%', sm: '30%' }, height: { xs: '45%', sm: '30%' } }} />
            </Box>
        </Box>
    )
}

export default YouDontHaveNotifications
