// Description: Show the plans that the user can choose
// Updated: 27/12/2024
// Author: Simon Franklin

// React
import React from 'react';

// Material UI
import { Box } from '@mui/material';

// Components and functions for the project
import Pricing from '../../components/Pricing/Pricing';
import { plans } from '../../utils/utils';
import Footer from '../../components/Footer/Footer.js';

// Render The Page
const PlanShowCase = () => {
  return (
    <Box sx={{
      minHeight: { xs: "calc(100vh - 70px)", sm: "calc(100vh - 100px)" },
      display: "flex",
      flexDirection: "column",
      background: "#F4F5FF"
    }}>
      <Pricing database={plans} type={"plans"} />
      <Box sx={{ paddingLeft: { xs: "20px", sm: "40px" }, paddingBottom: { xs: "5px", sm: "15px" }}}>
        <Footer />
      </Box>
    </Box>
  )
}

export default PlanShowCase