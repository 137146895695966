export const initialCampaignFields = [
    {
      fieldName: "Título da Campanha",
      value: "",
      step: 1,
      type: "normal",
      fullWidth: true,
    },
    {
      fieldName: "Descrição da Campanha",
      value: "",
      step: 1,
      type: "textarea",
      fullWidth: true,
    },
    {
      fieldName: "Data de início",
      value: "",
      step: 1,
      type: "date",
      fullWidth: false,
    },
    {
      fieldName: "Data de término",
      value: "",
      step: 1,
      type: "date",
      fullWidth: false,
    },
    {
      fieldName: "Horário de início",
      value: "",
      step: 1,
      type: "time",
      fullWidth: false,
    },
    {
      fieldName: "Tipo de Envio",
      value: "",
      step: 1,
      type: "select",
      fullWidth: false,
      options: [
        { title: "Atrasos", id: "Atrasos" },
        { title: "Avisos", id: "Avisos" },
        { title: "Faturas", id: "Faturas" },
        { title: "Inadimplências", id: "Inadimplências" },
        { title: "Marketing", id: "Marketing" },
        { title: "Outros", id: "Outros" },
      ],
    },
    {
      fieldName: "Deseja enviar pelo WhatsApp?",
      value: "",
      step: 2,
      type: "select",
      fullWidth: true,
      options: ["Sim", "Não"],
    },
    {
      fieldName: "Deseja enviar pelo SMS?",
      value: "",
      step: 2,
      type: "select",
      fullWidth: true,
      options: ["Sim", "Não"],
    },
    {
      fieldName: "Deseja enviar pelo Email?",
      value: "",
      step: 2,
      type: "select",
      fullWidth: true,
      options: ["Sim", "Não"],
    },
    {
      fieldName: "Qual modelo vai usar no WhatsApp?",
      value: "",
      step: 3,
      type: "select",
      fullWidth: true,
      options: ["Modelo 1", "Modelo 2"],
    },
    {
      fieldName: "Qual modelo vai usar no SMS?",
      value: "",
      step: 3,
      type: "select",
      fullWidth: true,
      options: ["Modelo 1", "Modelo 2"],
    },
    {
      fieldName: "Qual modelo vai usar no Email?",
      value: "",
      step: 3,
      type: "select",
      fullWidth: true,
      options: ["Modelo 1", "Modelo 2"],
    },
    {
      fieldName: "Selecionar Listagem",
      value: "",
      step: 4,
      type: "select",
      fullWidth: true,
      options: ["Listagem 1", "Listagem 2", "Listagem 3"],
    },
    {
      fieldName: "Datas de envio dinâmicas",
      value: "",
      step: 5,
      type: "select",
      fullWidth: false,
      options: [{ title: "Sim", id: true }, { title: "Não", id: false }],
    },
    {
      fieldName: "Em qual coluna estão as datas?",
      value: "",
      step: 5,
      type: "select",
      fullWidth: false,
      options: ["Coluna 1", "Coluna 2", "Coluna 3"],
    },
    {
      fieldName: "Enviar somente em dias úteis?",
      value: "",
      step: 5,
      type: "select",
      fullWidth: false,
      options: [{ title: "Sim", id: true }, { title: "Não", id: false }],
    },
    {
      fieldName: "Qual será a referência de envio?",
      value: "",
      step: 5,
      type: "select",
      fullWidth: false,
      options: [{ title: "Antes da data", id: 'before' }, { title: "Depois da data", id: 'after' }, { title: "No mesmo dia", id: 'sameDay' }],
    },
    {
      fieldName: "Quantos dias antes ou depois?",
      value: "",
      step: 5,
      type: "text",
      fullWidth: false,
    },
    {
      fieldName: "Deseja adicionar regra de envio?",
      value: "",
      step: 5,
      type: "select",
      fullWidth: true,
      options: [{ title: "Sim", id: true }, { title: "Não", id: false }],
    },
    {
      fieldName: "Coluna da regra",
      value: "",
      step: 5,
      type: "select",
      fullWidth: false,
      options: ["Coluna 1", "Coluna 2", "Coluna 3"],
    },
    {
      fieldName: "Operador",
      value: "",
      step: 5,
      type: "select",
      fullWidth: false,
      options: [{ title: "Igual", id: '==' }, { title: "Diferente", id: '!=' }, { title: "Nulo", id: 'is null' }, { title: "Não Nulo", id: 'is not null' },],
    },
    {
      fieldName: "Valor",
      value: "",
      step: 5,
      type: "text",
      fullWidth: false,
    },
  ]