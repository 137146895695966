// Description: Component that render the card data
// Updated:
// Author: Simon Franklin

// React
import React from 'react'

// Material UI Components
import { Box, Button, Typography } from '@mui/material'
import themeColors from '../ThemeColors/ThemeColors'
import { useNavigate } from 'react-router-dom'
import CheckIcon from './CheckIcon';
import { formatCurrency } from '../../utils/utils';

// Components and Functions for the project

const PlanCard = ({ plan, selected, setSelected }) => {
    const navigate = useNavigate();

    const goToCheckout = () => {
        localStorage.setItem("selected_plan", JSON.stringify({ name: `Plano ${plan.planName}`, price: plan.planPrice, features: getTrueFeatures(plan.resources, plan.dataSource, plan.packageIncluded)}));
        navigate("/planos/carrinho");
    }

    function getTrueFeatures(resources, dataSource, packageIncluded) {
        const allFeatures = [...resources, ...dataSource, ...packageIncluded];

        return allFeatures
          .filter(feature => feature.have)
          .map(feature => feature.name);
      }

    return (
        <Box onClick={() => setSelected(plan)} sx={{ width: { xs: "100%", sm: "367px" }, height: { xs: "580px", sm: "610px" }, background: selected === plan ? themeColors.purple500 : "#FFF", border: "1px solid #DFDFDF", borderRadius: "8px", padding: "30px 40px", display: "flex", flexDirection: "column", cursor: "pointer" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                <Typography sx={{ fontSize: { xs: "20px", sm: "24px" }, color: selected === plan ? "#FFF" : themeColors.purple500, fontWeight: "600" }}>
                    {plan.planName}
                </Typography>
                <Typography sx={{ fontSize: { xs: "16px", sm: "20px" }, color: selected === plan ? "#FFF" : "#300D68", fontWeight: "600" }}>
                    {formatCurrency(plan.planPrice, 'BRL')} / mês
                </Typography>
            </Box>

            <Typography sx={{ fontSize: { xs: "12px", sm: "14px" }, color: selected === plan ? "#FFF" : "#52525B", fontWeight: "500", marginBottom: "20px" }}>
                {plan.description}
            </Typography>

            <Box sx={{ display: "flex", flexDirection: "column", gap: "12px", marginBottom: "15px" }}>
                <Typography sx={{ fontSize: { xs: "14px", sm: "16px" }, color: selected === plan ? "#FFF" : "#52525B", fontWeight: "600" }}>
                    Recursos
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "6px" }}>
                    {plan.resources.map((feature) => (
                        <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
                            <CheckIcon have={feature.have} />
                            <Typography key={feature} children={feature.name} sx={{ fontSize: { xs: "12px", sm: "13px" }, color: selected === plan ? "#FFF" : "#848484", textDecoration: feature.have ? "none" : "line-through" }} />
                        </Box>
                    ))}
                </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "12px", marginBottom: "15px" }}>
                <Typography sx={{ fontSize: { xs: "14px", sm: "16px" }, color: selected === plan ? "#FFF" : "#52525B", fontWeight: "600" }}>
                    Fontes de Dados
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "6px" }}>
                    {plan.dataSource.map((feature) => (
                        <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
                            <CheckIcon have={feature.have} />
                            <Typography key={feature} children={feature.name} sx={{ fontSize: { xs: "12px", sm: "13px" }, color: selected === plan ? "#FFF" : "#848484", textDecoration: feature.have ? "none" : "line-through" }} />
                        </Box>
                    ))}
                </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "12px", marginBottom: "25px" }}>
                <Typography sx={{ fontSize: { xs: "14px", sm: "16px" }, color: selected === plan ? "#FFF" : "#52525B", fontWeight: "600" }}>
                    Pacotes Inclusos
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "6px" }}>
                    {plan.packageIncluded.map((feature) => (
                        <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
                            <CheckIcon have={feature.have} />
                            <Typography key={feature} children={feature.name} sx={{ fontSize: { xs: "12px", sm: "13px" }, color: selected === plan ? "#FFF" : "#848484", textDecoration: feature.have ? "none" : "line-through" }} />
                        </Box>
                    ))}
                </Box>
            </Box>

            <Button onClick={goToCheckout} sx={{ width: "283.93px", height: "41.87px", borderRadius: "6px", border: selected === plan ? "1px solid #FFF" : "1px solid #B7B7B7" , color: themeColors.purple500, background: selected === plan ? "#FFF" : "", fontWeight: "600", "&:hover": { background: "#F9F9F9" }, alignSelf: "center", display: selected === plan ? "block" : "none" }}>
                Pagar Agora
            </Button>
        </Box>
    )
}

export default PlanCard