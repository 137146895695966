// Description: Page to Create the email templates for the client
// Updated:
// Author: Simon Franklin

// React
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Material UI Components
import { Box } from '@mui/material';

// Components and functions for the project
import { GetVariables } from '../../api/backend/Templates/GetVariables';
import { isAuthenticated } from '../../authMiddleware';
import { CreateTemplate } from '../../api/backend/Templates/CreateTemplate';
import HeaderPage from '../../components/HeaderPage/HeaderPage';
import { initialHtml, personalizedBackgrounds } from '../../utils/templates';
import Loading from '../../components/Loading/Loading';
import DynamicForm from '../../components/HTMLForm/DynamicForm';
import { useAuth } from '../../context/AuthContext';
import { createObjectFromArray } from '../../utils/utils';
import themeColors from '../../components/ThemeColors/ThemeColors';
import CampaignModal from '../../components/Modals/CampaignModal';

const CreateEmailTemplate = () => {
  // Authentication and state variables
  const { userAuthenticated } = useAuth();

  // Verify if the user is creating a campaign
  const creatingCampaignWithHelpMode = JSON.parse(sessionStorage.getItem("creating_campaign")) || null;

  // Email Template States
  const [title, setTitle] = useState("");
  const [emailTitle, setEmailTitle] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [variablesOfTemplate, setVariablesOfTemplate] = useState({});
  const [isEmailTemplatePersonalized, setIsEmailTemplatePersonalized] =
    useState(
      JSON.parse(localStorage.getItem("isEmailTemplatePersonalized")) || false
    );
  const typeChannel = "E-mail"
  const [typeMessage, setTypeMessage] = useState("Atrasos");
  const [backgroundColor, setBackgroundColor] = useState("#6558de");
  const palletOfColors = [
    "#BB0404", "#DEA73F", "#FFFFFF", "#1837D7", "#ED15BE", "#6558de"
  ];
  const [openModalCampaign, setOpenModalCampaign] = useState(false);

  const [selected, setSelected] = useState("Atrasos");
  const htmlToBeRendered = initialHtml
    .replace("$json.Title01", "Title01")
    .replace("$json.Title02", "URL01")
    .replace("{ Background }", backgroundColor)
    .replace("{ TitleColor }", backgroundColor === "#FFFFFF" ? themeColors.purple500 : "#FFFFFF");

  // Function to extract variables
  const extractVariables = (html) => {
    const pattern = /{{\s*(.*?)\s*}}/g;
    let variables = [];
    let match;
    while ((match = pattern.exec(html)) !== null) {
      variables.push(match[1]);
    }
    return variables;
  };

  const variables = extractVariables(htmlToBeRendered);
  const [formState, setFormState] = useState(createObjectFromArray(variables, ""));

  // Function to replace the dynamic variables in the HTML
  const replaceVariables = (html, formState) => {
    return html.replace(/{{\s*(.*?)\s*}}/g, (match, p1) => {
      const value = formState[p1] || "";

      return value.replace(/\n/g, '<br />');
    });
  };

  // updated HTML with the value of the inputs
  const updatedHtml = replaceVariables(htmlToBeRendered, formState);

  const Navigate = useNavigate();

  // Effect to Check Authentication and Fetch User Info
  useEffect(() => {
    if (userAuthenticated) {
      const { decryptedData } = isAuthenticated();
      setUserInfo(decryptedData);
    } else {
      Navigate("/");
    }
  }, []);

  // Effect to Fetch Data from API
  useEffect(() => {
    if (userInfo["UUID"]) {
      fetchData();
    }
  }, [userInfo]);

  // Catch template variables
  const fetchData = async () => {
    try {

      const translations = {
        Personal_information: "Informações Pessoais",
        Contact: "Contato",
        Address: "Endereço",
        Financial_References: "Referências Financeiras",
      };

      const resultJson = await GetVariables();

      console.log(resultJson, "Result JSON");
      const result = resultJson.reduce((acc, item) => {
        const category = translations[item[1]] || item[1]; // Traduz a categoria, ou usa o nome original caso não haja tradução
        if (!acc[category]) {
          acc[category] = [];
        }

        // limita a 10 itens

        acc[category].push(item[0]);

        return acc;
      }, {});

      console.log(result, "Template Variables");

      setVariablesOfTemplate(result);
    } catch (error) {
      console.error("Error fetching data from API:", error);
    }
  };

  // Function to save the template
  const saveTemplate = async () => {
    const template = {
      template: {
        html: htmlToBeRendered,
        variables: [
          {
            emailTitle: emailTitle,
            title01: formState.Title01,
            logo: formState.Logo,
            text: formState.Text,
            themeColor: backgroundColor
          },
        ],
      },
    };

    let info = {
      uuid_client: userInfo["UUID"],
      id: userInfo["EMAIL"],
      token: userInfo["TOKEN"],
      title: title,
      message_json: template,
      integration_system: "aws",
      status: "Pending",
      msg_type: typeMessage,
      channel_type: "E-mail",
      type: 'HTML',
    };

    info = JSON.stringify(info);

    const resultJson = await CreateTemplate(info);

    if (resultJson["status"] === "success") {
      creatingCampaignWithHelpMode === true ? setOpenModalCampaign(true) : Navigate("/modelos");
    }
  };

  return (
    <>
      <HeaderPage />
      <Box
        sx={{
          position: "relative",
          width: "100%",
          minHeight: "calc(100vh - 100px)"
        }}
      >
        <DynamicForm
          title={title}
          setTitle={setTitle}
          variablesOfTemplate={variablesOfTemplate}
          emailTitle={emailTitle}
          setEmailTitle={setEmailTitle}
          Navigate={Navigate}
          typeMessage={typeMessage}
          setTypeMessage={setTypeMessage}
          updatedHtml={updatedHtml}
          variables={variables}
          handleSubmit={saveTemplate}
          formState={formState}
          setFormState={setFormState}
          selected={selected}
          setSelected={setSelected}
          handleColorChange={setBackgroundColor}
          mapHTMLColors={palletOfColors}
          selectedColor={backgroundColor}
          isHTMLTemplate
          dropzone
        />
      </Box>
      <CampaignModal
        cancelText="Criar mais um modelo"
        confirmText="Retornar ao formulário"
        subTitle={"Percebemos que você está configurando uma campanha. Gostaria de retornar ao formulário?"}
        isOpen={openModalCampaign}
        onClose={() => setOpenModalCampaign(false)}
        onCancel={() => Navigate("/modelos/canal")}
        onConfirm={() => (Navigate("/campanhas/adicionar/modo-ajuda"), sessionStorage.setItem("ReturToCampaign", true))}
        title={"Modelo criado com sucesso"}
        iconColor={themeColors.purple400}
      />
    </>
  )
}

export default CreateEmailTemplate