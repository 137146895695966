// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes movedown {
    0% {
        opacity: 0;
        transform: translateY(-50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes moveright {
    0% {
        opacity: 0;
        transform: translateX(-50px);
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

.cardInfo_card {
    opacity: 0;
    animation: movedown 1s linear forwards;
}


.cardInfo_card:nth-child(1) {
    animation-delay: 0s;
}

.cardInfo_card:nth-child(2) {
    animation-delay: .5s;
}

.cardInfo_card:nth-child(3) {
    animation-delay: 1s;
}

@media screen and (max-width: 600px) {
    .cardInfo_card {
        opacity: 0;
        animation: moveright 1s linear forwards;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Cards/cardInfo.css"],"names":[],"mappings":"AAAA;IACI;QACI,UAAU;QACV,4BAA4B;IAChC;;IAEA;QACI,UAAU;QACV,0BAA0B;IAC9B;AACJ;;AAEA;IACI;QACI,UAAU;QACV,4BAA4B;IAChC;;IAEA;QACI,UAAU;QACV,0BAA0B;IAC9B;AACJ;;AAEA;IACI,UAAU;IACV,sCAAsC;AAC1C;;;AAGA;IACI,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI;QACI,UAAU;QACV,uCAAuC;IAC3C;AACJ","sourcesContent":["@keyframes movedown {\n    0% {\n        opacity: 0;\n        transform: translateY(-50px);\n    }\n\n    100% {\n        opacity: 1;\n        transform: translateY(0px);\n    }\n}\n\n@keyframes moveright {\n    0% {\n        opacity: 0;\n        transform: translateX(-50px);\n    }\n\n    100% {\n        opacity: 1;\n        transform: translateX(0px);\n    }\n}\n\n.cardInfo_card {\n    opacity: 0;\n    animation: movedown 1s linear forwards;\n}\n\n\n.cardInfo_card:nth-child(1) {\n    animation-delay: 0s;\n}\n\n.cardInfo_card:nth-child(2) {\n    animation-delay: .5s;\n}\n\n.cardInfo_card:nth-child(3) {\n    animation-delay: 1s;\n}\n\n@media screen and (max-width: 600px) {\n    .cardInfo_card {\n        opacity: 0;\n        animation: moveright 1s linear forwards;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
