import { Box, Button, MenuItem, Select, Typography } from '@mui/material';
import React from 'react';
import themeColors from '../../../components/ThemeColors/ThemeColors';
import Flame from '../flame.png';
import { formatCurrency } from '../../../utils/utils';

const Step1 = ({ product, setCurrentStep, months, setMonths, subtotal, economy }) => {
    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", flexDirection: "column", padding: "33px 45px", gap: "10px", background: themeColors.purple500, borderRadius: "16px", marginBottom: { xs: "34px", sm: "64px"} }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                    <Box component="img" src={Flame} sx={{ objectFit: "contain", width: { xs: "17px", sm: "20px" }, height: { xs: "24px", sm: "27px" } }} />
                    <Typography children="Descontos Imperdíveis" sx={{ color: "#FFF", fontSize: { xs: "18px", sm: "24px" }, fontWeight: 600 }} />
                </Box>
                <Typography children="Antecipe mais de uma assinatura, economize ainda mais e aproveite descontos exclusivos ao acumular meses!" sx={{ color: "#FFF", fontSize: { xs: "14px", sm: "16px" }, fontWeight: 600 }} />
            </Box>

            <Typography sx={{ fontSize: { xs: "24px", sm: "40px" }, color: "#2F1C6A", fontWeight: 600, marginBottom: { xs: "26px", sm: "46px"} }}>
                Seu Carrinho
            </Typography>

            <Box sx={{ display: "flex", gap: "28px", flexDirection: { xs: "column", sm: "row" } }}>
                <Box sx={{ display: "flex", flex: "1", padding: "30px", borderRadius: "16px", background: "#FFF", justifyContent: "space-between", flexDirection: "column" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", borderBottom: `1px solid #989898`, paddingBottom: "16px", marginBottom: "26px" }}>
                        <Typography sx={{ fontSize: { xs: "20px", sm: "24px" }, color: "#2F1C6A", fontWeight: 600 }}>
                            {product.name}
                        </Typography>

                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: { xs: "start", sm: "center" }, marginBottom: "37px", flexDirection: { xs: "column", sm: "row" } }}>
                        <Box sx={{ display: "flex", alignItems: "center", gap: "45px", flexDirection: { xs: "column", sm: "row" } }}>
                            <Select
                                variant="outlined"
                                margin="normal"
                                name="paymentType"
                                defaultValue={months}
                                sx={{ background: '#FFF', marginTop: '10px', marginBottom: { xl: '8px', lg: '5px' }, border: "1px solid #BFBFBF", borderRadius: "6px", width: "194px", height: "48px" }}
                                onChange={(e) => setMonths(e.target.value)}
                            >
                                <MenuItem value={1}>1 mês</MenuItem>
                                <MenuItem value={2}>2 mêses</MenuItem>
                                <MenuItem value={3}>3 mêses</MenuItem>
                                <MenuItem value={4}>4 mêses</MenuItem>
                                <MenuItem value={5}>5 mêses</MenuItem>
                                <MenuItem value={6}>6 mêses</MenuItem>
                            </Select>

                            <Box sx={{ display: "flex", background: "#00B090", borderRadius: "16px", width: { xs: "auto", sm: "220px" }, height: "35px", justifyContent: "center", alignItems: "center", padding: { xs: "0 20px", sm: "0" }, marginBottom: { xs: "20px", sm: "0" } }}>
                                <Typography sx={{ color: "#FFF", fontSize: { xs: "14px", sm: "16px" } }}>
                                    Economizando {formatCurrency(economy, 'BRL')}
                                </Typography>
                            </Box>
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                            <Typography sx={{ fontSize: { xs: "16px", sm: "20px" }, color: "#2F1C6A", fontWeight: 600 }}>
                                {formatCurrency(subtotal / months, 'BRL')}/mês
                            </Typography>
                            <Typography sx={{ fontSize: { xs: "14px", sm: "16px" }, color: "#2F1C6A", textDecoration: "line-through" }}>
                                {formatCurrency(product.price, 'BRL')}/mês
                            </Typography>
                        </Box>
                    </Box>

                    <Box sx={{ background: "#DDD4FF", borderRadius: "6px", height: "48px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "13px" }}>
                        <Typography sx={{ fontSize: { xs: "14px", sm: "16px" }, }}>
                            A cada mês antecipado, ganhe 10% de desconto sobre o valor total.
                        </Typography>
                    </Box>
                </Box>

                <Box sx={{ display: "flex", width: { xs: "100%", sm: "35%" }, padding: "30px", borderRadius: "16px", background: "#FFF", flexDirection: "column", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "30px", marginBottom: { xs: "20px", sm: "0" } }}>
                        <Typography sx={{ fontSize: { xs: "16px", sm: "24px" }, color: "#2F1C6A", fontWeight: 600 }}>
                            Subtotal
                        </Typography>

                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography sx={{ fontSize: { xs: "16px", sm: "24px" }, color: "#2F1C6A" }}>
                                De: <span style={{ textDecoration: "line-through" }}>{formatCurrency(product.price * months, 'BRL')}</span>
                            </Typography>
                            <Typography sx={{ fontSize: { xs: "16px", sm: "24px" }, color: "#2F1C6A", fontWeight: 600 }}>
                                Por: {formatCurrency(subtotal, 'BRL')}
                            </Typography>
                        </Box>
                    </Box>

                    <Button variant='contained' sx={{ fontWeight: 600, padding: "14px" }} onClick={() => setCurrentStep(2)}>
                        Continuar
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default Step1