// Description: Show the packages that the user can choose
// Updated: 20/12/2024
// Author: Simon Franklin

// React
import React from 'react';

// Material UI
import { Box } from '@mui/material';

// Components and functions for the project
import HeaderPage from '../../components/HeaderPage/HeaderPage.js';
import Pricing from '../../components/Pricing/Pricing';
import { plans } from '../../utils/utils';
import Footer from '../../components/Footer/Footer.js';

// Render The Page
const PackagesShowCase = () => {
  return (
    <Box sx={{
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between"
    }}>
      <Pricing database={plans} type={"packages"} />
      <Box sx={{ paddingLeft: { xs: "20px", sm: "40px" }, paddingBottom: { xs: "5px", sm: "15px" }}}>
        <Footer />
      </Box>
    </Box>
  )
}

export default PackagesShowCase