// Description: This component render a Card List
// Update: 12/08/2024

// React Components and DOM Elements
import React, { useEffect, useState } from 'react'

// Material UI Components
import { Box, Grid, Rating, Typography } from '@mui/material'

// Components and functions from the project
import StatusIcon from '../StatusLabel/StatusIcon'
import StatusLabel from '../StatusLabel/StatusLabel'
import Tooltip from '@mui/material/Tooltip';

import Titles from "../Titles/Titles"


const CreateCardList = ({ title, text, icon, iconColor, iconBackground, titleColor, handleClick, alreadyPaid, positionLeft, heightIcon, subtitle, type }) => {

    const [showAlert, setShowAlert] = useState(false);
    const [tooltipClass, setTooltipClass] = useState('');
    const [isMainContentCollapsed, setIsMainContentCollapsed] = useState(false);

    useEffect(() => {
        const mainContent = document.querySelector('.MainContent');
        if (mainContent) {
            setIsMainContentCollapsed(mainContent.classList.contains('collapsed'));
        }
    }, [showAlert]);





    return (

        <Tooltip
            title={
                alreadyPaid ? (
                    null
                ) : (
                    <Typography sx={{ display: type === 'channel' ? 'inline-block' : 'none' }} variant="body2">
                        Essa funcionalidade não está disponível no seu plano. Entre em contato com o suporte para mais informações.
                    </Typography>
                )
            }
            arrow
            placement="top"
        >
            <Grid
                item
                xs={12}
                lg={12}
                sx={{
                    display: "flex",
                    gap: "20px",
                    padding: "20px",
                    alignItems: "center",
                    border: "1px solid #D9D9DC",
                    borderRadius: "12px",
                    background: "#fff",
                    position: "relative",
                    cursor: alreadyPaid ? "pointer" : "not-allowed",
                }}
                onClick={alreadyPaid ? handleClick : null}
            >
                <Box
                    display={"flex"}
                    sx={{
                        minWidth: "80px",
                        minHeight: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "6px",
                        background: iconBackground ? iconBackground : "#DDD4FF",

                    }}
                >
                    <StatusIcon status={icon} color={iconColor} height={heightIcon ? heightIcon : "30px"} width={heightIcon ? heightIcon : "30px"} />
                </Box>
                <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} >
                    <Titles styles={{ fontSize: { xs: "16px", sm: "20px" } }} color={titleColor ? titleColor : "#8033F4"} children={title} font={"medium"} />
                    <Titles styles={{ fontSize: { xs: "13px", sm: "17px", marginTop: '10px' } }} color={"#303035"} children={subtitle} font={"medium"} />
                    <Titles styles={{ fontSize: { xs: "13px", sm: "16px" } }} color={"#000"} children={text} />
                </Box>


                <div
                    className='tooltip-wrapper'
                    style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        cursor: "pointer"
                    }}
                >
                    <div style={{ display: type === 'channel' ? 'block' : 'none' }}>
                        <Tooltip title={alreadyPaid ? <Typography variant="body2">Essa funcionalidade faz parte do seu plano.</Typography> : null} arrow placement='top'>
                            <span>
                                <StatusLabel
                                    status={alreadyPaid ? 'Habilitado' : 'Desabilitado'}
                                />
                            </span>
                        </Tooltip>
                    </div>
                </div>
            </Grid>
        </Tooltip>
    )
}

export default CreateCardList