import React from 'react';
import { Grid } from '@mui/material';
import CreateCardList from "./CreateCardList";

const CardList = ({ data, type = 'channel' }) => {
  return (
    <Grid container style={{ marginTop: "20px" }} spacing={2}>
      {
        data.map((content, index) => (
          <Grid item xs={12} lg={6} key={index} style={{ display: 'flex', flexDirection: 'column' }}>
            <CreateCardList 
              title={content.title} 
              text={content.text} 
              icon={content.icon} 
              titleColor={content.titleColor} 
              handleClick={content.onClick} 
              alreadyPaid={content.pago} 
              iconBackground={content.iconBackground} 
              iconColor={content.iconColor} 
              heightIcon={content.heightIcon} 
              subtitle={content.subtitle} 
              type={type}
            />
          </Grid>
        ))
      }
    </Grid>
  );
};

export default CardList;
