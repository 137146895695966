import React, { useState } from 'react';
import PlanCard from './../../components/Pricing/PlanCard';
import { plans } from '../../utils/utils';


const PlanShowCase = () => {

    const [selected, setSelected] = useState(() => {
        const proZapPlan = plans.find(plan => plan.planName === "ProZap");
        return proZapPlan || {};
    });

    return (
        <>
            <div style={{ overflow: 'hidden' , padding: '20px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', gap: '20px' }}>

                {plans.map((data) => (
                    data.planName !== "FreeZap" ? <PlanCard key={data.planName} plan={data} selected={selected} setSelected={setSelected} /> : null
                ))}
            </div>
        </>
    )
}

export default PlanShowCase