// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*

Desc: This file contains the styles for the Error page.
update 12/08/2024

*/

.ErroContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .ErroCircle {
    border: 2px solid #ffffff;
    border-radius: 50%;
    width: 500px;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px 5px rgba(92, 94, 94, 0.2); /* Adiciona uma sombra ao redor do círculo */
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Error/Erro.css"],"names":[],"mappings":"AAAA;;;;;CAKC;;AAED;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,8CAA8C,EAAE,4CAA4C;EAC9F","sourcesContent":["/*\n\nDesc: This file contains the styles for the Error page.\nupdate 12/08/2024\n\n*/\n\n.ErroContainer {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n  }\n  \n  .ErroCircle {\n    border: 2px solid #ffffff;\n    border-radius: 50%;\n    width: 500px;\n    height: 500px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    box-shadow: 0 0 10px 5px rgba(92, 94, 94, 0.2); /* Adiciona uma sombra ao redor do círculo */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
