// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.template_input[contentEditable="true"]:empty:before {
    content: attr(data-placeholder);
    color: rgb(132, 132, 132);
}

.template_input::-webkit-scrollbar {
    display: none;
}

.template_input {
    scrollbar-width: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Input/TemplateInput.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,yBAAyB;AAC7B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".template_input[contentEditable=\"true\"]:empty:before {\n    content: attr(data-placeholder);\n    color: rgb(132, 132, 132);\n}\n\n.template_input::-webkit-scrollbar {\n    display: none;\n}\n\n.template_input {\n    scrollbar-width: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
