// Description: Render the different options to the user
// Updated:
// Author: Simon Franklin

// React
import React, { useState } from 'react';

// Material UI Components
import { Box, Typography } from '@mui/material';
import PlanCard from './PlanCard';
import PackageCard from './PackageCard';
import { packs } from '../../utils/utils';

// Render The Component
const Pricing = ({ type, database }) => {
    
    // plan variables
    const [selected, setSelected] = useState(() => {
        const proZapPlan = database.find(plan => plan.planName === "ProZap");
        return proZapPlan || {}; 
    });

    // pack variables
    const [emailPackNumber, setEmailPackNumber] = useState(1);
    const [smsPackNumber, setSmsPackNumber] = useState(1);

    return (
        <Box sx={{ padding: { xs: "20px", sm: "40px 60px" }, display: "flex", flexDirection: "column", gap: { xs: "30px", sm: "60px" } }}>
            <Box sx={{ display: "flex", width: "100%", flexDirection: "column", alignItems: { xs: "start", sm: "center" }, gap: { xs: "16px", sm: "26px" } }}>
                <Typography sx={{ fontSize: { xs: "24px", sm: "30px" }, color: "#18181B", fontWeight: "700" }}>
                    {type === "packages" ? "Nossos Pacotes de Mensagens" : "Nossos Planos de Assinatura"}
                </Typography>
                <Typography sx={{ fontSize: { xs: "14px", sm: "16px" }, color: "#18181B", textAlign: { sm: "center" }, maxWidth: "790px" }}>
                    {type === "packages" ? "Adquira pacotes personalizados de mensagens para WhatsApp, Email e SMS. Perfeito para empresas que precisam de flexibilidade no envio em massa sem comprometer a qualidade e o alcance. Escolha o pacote que melhor atende às suas campanhas!" : "Escolha o plano ideal para suas necessidades. Oferecemos opções que incluem o envio de mensagens em massa via WhatsApp, Email e SMS, com recursos exclusivos como suporte, automação e relatórios detalhados. Encontre o plano perfeito para o seu negócio!"}
                </Typography>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center", gap: type === "packages" ? { xs: "20px", sm: "63px" } : { xs: "20px", sm: "40px" }, flexDirection: { xs: "column", sm: "row" }, alignItems: "center" }}>
                {type === "packages" ? (
                    <>
                        <PackageCard pack={packs[0]} setPackNumber={setEmailPackNumber} setSelected={setSelected} packNumber={emailPackNumber} />
                        <PackageCard pack={packs[1]} setPackNumber={setSmsPackNumber} setSelected={setSelected} packNumber={smsPackNumber} />
                    </>
                ) : (
                    <>
                        {database.map((data) => (
                            data.planName !== "FreeZap" ? <PlanCard key={data.planName} plan={data} selected={selected} setSelected={setSelected} /> : null
                        ))}
                    </>
                )}
            </Box>
        </Box>
    )
}

export default Pricing