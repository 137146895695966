import React from 'react'

const MinusIcon = ({ width, height, color }) => {
    return (
        <svg width={width ? width : "24"} height={height ? height : "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 12H19" stroke={color ? color : "black"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default MinusIcon