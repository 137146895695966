import { Box } from '@mui/material';
import React from 'react';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import themeColors from '../ThemeColors/ThemeColors';

const StepIndicator = ({ steps, currentStep }) => {
  return (
    <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center', flexWrap: 'wrap' }}>
      {steps.map((step) => (
        <Box
          key={step.stepNumber}
          sx={{
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            flexWrap: 'nowrap',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '28px',
              height: '28px',
              borderRadius: '50%',
              fontSize: { xs: '10px', sm: '14px', md: '16px' },
              fontWeight: 600,
              color: step.stepNumber <= currentStep ? '#FFF' : '#000',
              background: step.stepNumber <= currentStep ? '#8033F4' : '#D9D9DC',
            }}
          >
            {step.stepNumber}
          </Box>

          <NavigateNextOutlinedIcon
            style={{
              fontSize: '25px', // Tamanho menor por padrão
              color: themeColors.purple600,
              display: step.stepNumber === steps.length ? 'none' : 'block',
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default StepIndicator;
