import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import Confirmed from '../BConfirmed.png';
import { useNavigate } from 'react-router-dom';

const BoletoSucess = () => {
    const navigate = useNavigate();

    return (
        <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
            <img src={Confirmed} style={{ width: '200px', height: '200px', marginBottom: '46px' }} alt="Pagamento confirmado" />
            <Typography sx={{ color: '#8142FC', fontSize: { xs: "24px", sm: "32px" }, fontFamily: 'Satoshi', marginBottom: '16px' }}>
                Boleto gerado com sucesso!
            </Typography>
            <Typography style={{ color: '#848484', fontSize: { xs: "14px", sm: "18px" }, fontFamily: 'Satoshi-Medium', maxWidth: '400px', textAlign: 'center', marginBottom: "110px" }}>
                Abaixo, você poderá visualizar o boleto. Ele também estará disponível no seu <a href="#" style={{ textDecoration: "underline", color: "#848484" }}>portal de cliente</a>. Após o pagamento, a compensação será realizada em até 3 dias úteis.
            </Typography>

            <Box sx={{ display: "flex", gap: "17px", alignItems: "center" }}>
                <Button onClick={() => navigate("/painel")} sx={{ width: "296px", height: "48px", borderRadius: "6px", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 500, color: "#8142FC", background: "#D4BDFF", "&:hover": { background: "#CBB3F9" }}}>
                    Painel inicial
                </Button>
                <Button onClick={() => {}} variant="contained" sx={{ width: "296px", height: "48px", borderRadius: "6px", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 500 }}>
                    Visualizar boleto
                </Button>
            </Box>
        </Box>
    )
}

export default BoletoSucess