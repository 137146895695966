// Desc: Page to add a new WhatsApp instance
// Update: 22/10/2024

// React Components and DOM Elements
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Material UI Components
import Box from '@mui/material/Box';
import axios from 'axios';

import { isAuthenticated } from '../../authMiddleware';
import { RequestCreate } from '../../api/backend/Request/RequestCreate';

import CardList from '../../components/CardList/CardList';
import CardModal from '../../components/CardList/CardModal';
import HeaderPage from '../../components/HeaderPage/HeaderPage';
import Loading from '../../components/Loading/Loading';
import Titles from '../../components/Titles/Titles';

import { ChannelAddEmail } from '../../api/backend/Channels/ChannelAddEmail';
import { ChannelAddSMS } from '../../api/backend/Channels/ChannelAddSMS';
import { ChannelAddWppAPI } from '../../api/backend/Channels/ChannelAddWppAPI';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import ThemeColors from '../../components/ThemeColors/ThemeColors';
import ModalR from '../../components/Modals/ModalR';
import CampaignModal from '../../components/Modals/CampaignModal';

function ChannelPageAdd() {

  const { authenticated } = isAuthenticated();
  const [userInfo, setUserInfo] = useState({});
  let Navigate = useNavigate();

  const creatingCampaignWithHelpMode = JSON.parse(sessionStorage.getItem("creating_campaign")) || null;
  const [showLoading, setShowLoading] = useState(true);
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [loading, setLoading] = useState(false);

  const [openModalConfirmOK, setOpenModalConfirmOK] = useState(false);
  const [openModalCampaign, setOpenModalCampaign] = useState(false);
  const [openModalConfirmError, setOpenModalConfirmError] = useState(false);

  const [messageError, setMessageError] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [planoAtual, setPlanoAtual] = useState("");

  const mapPlanFunctionsAllowed = {
    'FreeZap': [],
    'StartZap': ['WhatsApp Web', 'Email'],
    'ProZap': ['WhatsApp Web', 'Email', 'WhatsApp API'],
    'UltraZap': ['WhatsApp Web', 'Email', 'WhatsApp API']
  };

  const openModal = (type) => {
    setModalType(type);
    setShowModal(true);
  }

  const addRequest = async () => {

    let info = {

      title: 'Solicitação de Adição de Canal ' + modalType,
      description: 'Solicitação de adição de canal ' + modalType + ' para envio de mensagens personalizadas para os clientes.',
      status: 'Pendente',
      creationdate: new Date().toISOString(),
      updatedate: new Date().toISOString(),
      requesterid: userInfo['EMAIL'],
      user_client: userInfo['UUID'],
      body: {
        type: modalType,
        data: { input, input2, input3 }
      },
      priority: 'A Definir'

    }

    const addRequestUser = await RequestCreate(info);

  }

  const HandleAddChannel = async () => {
    setLoading(true);

    if (modalType === 'Email') {
      try {
        const addEmail = await ChannelAddEmail({ email: input, name: input2 });
        if (addEmail.message === 'Email identity added successfully') {

          let mensagem = (
            <span>
              Seu email foi <b style={{ color: '#8033F4' }}>adicionado</b> com sucesso!<br /><br />1. Verifique sua caixa de entrada para confirmar o email.<br />2. Após a confirmação, em até <strong style={{ color: '#000' }}>10 minutos</strong> seu canal de email estará disponível para uso.
            </span>
          );

          setMensagem(mensagem);
          setShowModal(false);
          creatingCampaignWithHelpMode ? setOpenModalCampaign(true) : setOpenModalConfirmOK(true);
          addRequest();
          setInput("");
          setInput2("");

        }
      } catch (error) {

        let mensagem = (
          <span>
            Ocorreu um erro ao adicionar o email.<br /> Consulte nosso <b style={{ color: '#8033F4' }}>suporte</b> para ficar por dentro de todos os detalhes e orientações.
          </span>
        );

        setMessageError(mensagem);
        setShowModal(false);
        setOpenModalConfirmError(true);
        setInput("");
        setInput2("");

      } finally {
        setLoading(false);
      }
    } else if (modalType === 'SMS') {
      try {
        const addSMS = await ChannelAddSMS({ 'confirm': input });

        if (addSMS.message === 'SMS channel added successfully') {

          let mensagem = (
            <span>
              Seu canal de SMS foi <b style={{ color: '#8033F4' }}>adicionado</b> com sucesso!<br /> No Brasil, o envio de SMS é feito através de um número curto.
            </span>
          );
          setMensagem(mensagem);
          setShowModal(false);
          creatingCampaignWithHelpMode ? setOpenModalCampaign(true) : setOpenModalConfirmOK(true);
          addRequest();
          setInput("");
          setInput2("");
        }

      } catch (error) {

        let mensagem = null

        if (error && error.message === 'Já existe um canal SMS para este cliente') {
          mensagem = (
            <span>
              Este cliente já possui um canal SMS ativo.<br /><br />O canal de SMS é um recurso unico por cliente. Consulte nosso <b style={{ color: '#8033F4' }}>suporte</b> para ficar por dentro de todos os detalhes e orientações.
            </span>
          );
        } else {
          mensagem = (
            <span>
              Ocorreu um erro ao adicionar o canal SMS.<br /><br /> Caso o erro persista, consulte nosso <b style={{ color: '#8033F4' }}>suporte</b> para ficar por dentro de todos os detalhes e orientações.
            </span>
          );
        }

        setMessageError(mensagem);
        setShowModal(false);
        setOpenModalConfirmError(true);
        setInput("");
        setInput2("");

      } finally {
        setLoading(false);
      }
    }

    else if (modalType === 'WhatsApp Business') {

      try {
        const addWppAPI = await ChannelAddWppAPI({ 'url': input, 'token': input2, 'name': input3 });

        if (addWppAPI.message === 'WhatsApp API channel added successfully') {

          let mensagem = (
            <span>
              Seu canal de WhatsApp API foi <b style={{ color: '#8033F4' }}>adicionado</b> com sucesso!<br />
            </span>
          );

          setMensagem(mensagem);
          setShowModal(false);
          creatingCampaignWithHelpMode ? setOpenModalCampaign(true) : setOpenModalConfirmOK(true);
          addRequest();
          setInput("");
          setInput2("");
          setInput3("");

        }

      } catch (error) {

        let mensagem = null

        if (error && error.message === 'Já existe um canal WhatsApp API para este cliente') {
          mensagem = (
            <span>
              Este cliente já possui um canal WhatsApp API ativo.<br /> Consulte nosso <b style={{ color: '#8033F4' }}>suporte</b> para ficar por dentro de todos os detalhes e orientações.
            </span>
          );
        } else {
          mensagem = (
            <span>
              Ocorreu um erro ao adicionar o canal WhatsApp API.<br /> Consulte nosso <b style={{ color: '#8033F4' }}>suporte</b> para ficar por dentro de todos os detalhes e orientações.
            </span>
          );
        }

        setMessageError(mensagem);
        setShowModal(false);
        setOpenModalConfirmError(true);
        setInput("");
        setInput2("");
        setInput3("");

      } finally {
        setLoading(false);
      }
    }
  }

  const HandleRedirectAdd = () => {
    Navigate('/canais/adicionar/whatsapp');
  }

  const HandleChannels = () => {
    creatingCampaignWithHelpMode === true ? Navigate("/campanhas/adicionar/modo-ajuda") : Navigate("/canais");
  }

  const canAddChannel = (channel) => {
    if (!mapPlanFunctionsAllowed[planoAtual]) {
      return false;  
    }
    return mapPlanFunctionsAllowed[planoAtual].includes(channel);
  };

  const verifica_plano = () => {

    const token = localStorage.getItem('jwt_token');
    const plano = axios.get('https://api-backend.123zap.com.br/plan/verify', {
      headers: {
        'Authorization': `Bearer ${token}`

      }
    }).then((response) => {
      console.log(response.data);
      setPlanoAtual(response.data.plan_name);
    }).catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    verifica_plano();
  }, []);

  useEffect(() => {
    if (authenticated) {
      const { decryptedData } = isAuthenticated();
      setUserInfo(decryptedData);
      console.log(decryptedData);
    }
  }, []);

  const channelData = [
    { title: "WhatsApp Web", subtitle: "Adicione um número de WhatsApp de forma fácil e rápida.", text: "A integração com o WhatsApp Web é uma solução simples e prática, ideal para empresas ou usuários que realizam envios em menor volume. Recomendada para operações de baixa demanda, como notificações esporádicas, acompanhamento de clientes ou pequenas campanhas.", icon: "WhatsApp", corFundo: "#DDD4FF", titleColor: "#8033F4", onClick: HandleRedirectAdd, pago: canAddChannel('WhatsApp Web') },
    { title: "WhatsApp API", subtitle: "Adicione um número de WhatsApp com a API Oficial.", text: "Essa solução é ideal para empresas que necessitam de maior estabilidade e desempenho em seus envios. Perfeita para operações de alto volume, como disparos de mensagens em massa, atendimento automatizado, campanhas contínuas e fluxos complexos de comunicação.", icon: "WhatsApp Business", corFundo: "#DDD4FF", titleColor: "#8033F4", onClick: () => openModal("WhatsApp Business"), pago: canAddChannel('WhatsApp API') },
    { title: "Email", subtitle: "Integre seu e-mail de comunicação diretamente à plataforma.", text: "Permitimos que você diversifique os canais de envio, garantindo que seus clientes recebam notificações por diferentes meios de comunicação.", icon: "Email", corFundo: "#DDD4FF", titleColor: "#8033F4", onClick: () => openModal("Email"), pago: canAddChannel('Email') },
    { title: "SMS", subtitle: "Solicite um canal para envio de SMS.", text: "O SMS é uma excelente opção para mensagens simples e diretas, como códigos de confirmação, cobranças rápidas ou até mesmo como alternativa ao WhatsApp. Além disso, o SMS oferece preços competitivos, tornando-se uma escolha vantajosa para uma comunicação rápida e eficaz.", icon: "SMS", corFundo: "#DDD4FF", titleColor: "#8033F4", onClick: () => openModal("SMS"), pago: canAddChannel('SMS') }
  ];

  useEffect(() => {
    setTimeout(() => setShowLoading(false), 1000);
  }, []);

  const HandleWhatsApp = () => {
    window.open('https://wa.me/553121300297?text=Ol%C3%A1%2C%20estou%20precisando%20de%20suporte!%20', '_blank');
  }


  if (showLoading) {
    return <Loading open={true} />;
  }

  return (
    <>
      <HeaderPage title="Add WhatsApp Instance" />
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          minHeight: { sm: 'calc(100vh - 100px)' },
          paddingBottom: 10,
        }}
      >
        <Box sx={{ padding: { xs: '20px 20px 0 20px', sm: '40px 40px 0 40px' } }}>
          <Titles styles={{ fontSize: { xs: "20px", sm: "24px" } }} children={"Qual Canal deseja adicionar?"} color={"#848484"} />
          <CardList data={channelData} />
        </Box>

        <CardModal isOpen={showModal} onClose={() => setShowModal(false)} type={modalType} input={input} setInput={setInput} onConfirm={HandleAddChannel} input2={input2} setInput2={setInput2} input3={input3} setInput3={setInput3} loading={loading} />
      </Box>
      <ModalR
        cancelText="Fechar"
        color={ThemeColors.purple500}
        confirmText="Verificar meus canais"
        subTitle={mensagem}
        isOpen={openModalConfirmOK}
        onClose={() => setOpenModalConfirmOK(false)}
        onConfirm={HandleChannels}
        title={""}
        emoji="emoji02"
        iconColor={ThemeColors.purple400}
      />
      
      <CampaignModal
        cancelText="Adicionar mais um canal"
        confirmText="Retornar ao formulário"
        subTitle={"Percebemos que você está configurando uma campanha. Gostaria de retornar ao formulário?"}
        isOpen={openModalCampaign}
        onClose={() => setOpenModalCampaign(false)}
        onCancel={() => Navigate("/canais/adicionar")}
        onConfirm={() => (HandleChannels(), sessionStorage.setItem("ReturToCampaign", true))}
        title={"Canal adicionado com sucesso"}
        iconColor={ThemeColors.purple400}
      />

      <ModalR
        cancelText="Fechar"
        color={ThemeColors.red500}
        confirmText="Chamar Suporte"
        subTitle={messageError}
        isOpen={openModalConfirmError}
        onClose={() => setOpenModalConfirmError(false)}
        onConfirm={HandleWhatsApp}
        title={""}
        emoji="emoji10"
        iconColor={ThemeColors.red400}
        iconButton={<WhatsAppIcon sx={{ color: 'white' }} />}
      />
    </>
  );
}

export default ChannelPageAdd;