// React
import React from 'react';

// Material UI Components
import { Box } from '@mui/material';

// Components and functions for the project
import { steps } from '../index.js';
import Titles from '../../../components/Titles/Titles.js';
import themeColors from '../../../components/ThemeColors/ThemeColors.js';
import checkIcon from '../check-icon.png';

const Step5 = ({ termsOfUse, setTermsOfUse }) => {

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                    flex: '1'
                }}
            >
                <Titles
                    styles={{
                        fontSize: { xs: '20px', lg: '24px' }
                    }}
                    children={steps[4].stepTitle}
                    bold
                    color={themeColors.purple500}
                />
                <Titles
                    styles={{ marginBottom: '2px', width: '100%', fontSize: { xs: '14px', lg: '16px' } }}
                    children={'Aceite os termos de uso da 123zap e finalize o cadastro.'}
                    color={themeColors.gray50}
                />
                <Box
                    sx={{
                        display: 'flex',
                        gap: '5px',
                        alignItems: 'center',
                        marginTop: { xs: '60px', sm: '120px' }
                    }}
                >
                    <Box
                        onClick={() => setTermsOfUse((prev) => !prev)}
                        sx={{
                            width: '20px',
                            height: '21px',
                            border:
                                termsOfUse
                                    ? 'none'
                                    : `1px solid ${themeColors.gray50}`,
                            borderRadius: '2px',
                            background:
                                termsOfUse
                                    ? `${themeColors.purple500}`
                                    : 'none',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}
                    >
                        <img
                            src={checkIcon}
                            alt='check'
                            style={{
                                display: termsOfUse ? 'block' : 'none',
                                width: '11px',
                                height: '8.9px',
                            }}
                        />
                    </Box>
                    <Titles styles={{
                        fontSize: { xs: '13px', sm: '16px' }
                    }}>
                        Concordo com os <a href='https://123zap.com.br/termos-de-uso/' target='_blank' style={{ color: themeColors.purple500 }}>termos de uso da plataforma</a>
                    </Titles>
                </Box>

            </Box>
        </>
    )
};

export default Step5;