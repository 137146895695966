// Custom functions to use in the project

import toast from "react-hot-toast";
import CustomAlert from "../components/Alerts/CustomAlert";
import { isAuthenticated } from '../authMiddleware';
import axios from "axios";
import themeColors from "../components/ThemeColors/ThemeColors";

// Function to format Price
export const formatCurrency = (valor, moeda) => {
  return valor.toLocaleString('pt-br', {
      style: 'currency',
      currency: moeda 
    })
}

// Function to show toasts
export const notify = (type, text, right) => {
  toast.remove();
  toast.custom(<CustomAlert message={text} type={type} right={right} />);
};

// Function to format texts that have so much length
export const truncateText = (text, number) => {
  if (text.length < number) {
    return text;
  }

  return text.substring(0, number) + "...";
};

// Function to check if cookies are expired
export const checkAuth = () => {
  const authStatus = isAuthenticated();
  const { authenticated } = authStatus;

  return authenticated
};

// Function to create a object from one array
export function createObjectFromArray(array, initialValue) {
  const result = {};
  array.forEach((str) => {
    result[str] = initialValue; // All properties start with this value
  });
  return result;
}

// Function to apply a mask for cpf or cnpj
export const insertMaskForCpfOrCnpj = (cpfOrCnpj) => {
  cpfOrCnpj = cpfOrCnpj.replace(/\D/g, ""); // Remove all that isn't a number

  if (cpfOrCnpj.length <= 11) {
    // Apply cpf mask
    cpfOrCnpj = cpfOrCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  } else {
    // Apply cnpj mask
    cpfOrCnpj = cpfOrCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }

  return cpfOrCnpj;
}

// Function to insert mask in a phone Number
export const insertMaskForPhoneNumber = (phone) => {
  const noMask = phone.replace(/\D/g, ''); // Remove all that isn't a number
  const { length } = noMask;

  if (length <= 11) { // Insert mask
    return noMask
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(length === 11 ? /(\d{5})(\d)/ : /(\d{4})(\d)/, '$1-$2');
  }

  return phone;
}

// Function to insert Mask in CEP
export const insertMaskForCep = (text) => {
  text = text.replace(/\D/g, "");
  text = text.replace(/(\d{5})(\d)/, '$1-$2');
  text = text.substring(0, 9);

  return text;
}

// Function to render the input content with the variables inside a span
export const updatedField = (text, variablesOfTemplate) => {
  if (variablesOfTemplate !== null && text) {
    const variables = Object.values(variablesOfTemplate).flat();
    variables.map((variable) => {
      const regex = new RegExp(`{{\\s*${variable}\\s*}}`, "g");
      text = String(text).replace(
        regex,
        `<span style="color: #4C8435; font-weight: 600;">{{${variable}}}</span>`
      );
    });
  }
  return text;
};

// Function to logout the user
export const handleLogout = (userInfo) => {
  const FRONTURL_BASED = process.env.REACT_APP_FRONTEND_URL;

  if (userInfo['UUID']) {
      axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/logout`, {
          id_client: userInfo['UUID']
      }).then((response) => {
          console.log('response:', response);
          
          // Remove o token do localStorage
          localStorage.removeItem('jwt_token');
          
          // Redireciona para a URL base
          window.location.href = `${FRONTURL_BASED}`;
      }).catch((error) => {
          console.error('error:', error);
      });
  }
};

// Function to get the location
export function getLocation(fullLocation) {

  // Verify if fullLocation is defined
  if (fullLocation) {

      const locationName = fullLocation.split('/')[1];
      
      // Corrigindo a comparação sem a barra inicial
      if (locationName === 'cobran%C3%A7as') {
          return '/cobranças';
      }

      if (locationName === 'configura%C3%A7%C3%B5es') {
          return '/configurações';
      }
      
      return '/' + locationName;
  } else {
      return '';
  }
}

// ColorMapping to render the notification right styles
export const colorMapping = {
    1: {
        iconColor: '#CD4747',
        backgroundColor: themeColors.red01,
        textColor: '#4E1B1D',
    },
    2: {
        iconColor: '#DEA73F',
        backgroundColor: themeColors.yellow01,
        textColor: '#8D4D1B',
    },
    3: {
        iconColor: '#8142FC',
        backgroundColor: '#DDD4FF',
        textColor: '#4F189A',
    },
    4: {
        iconColor: '#4C8435',
        backgroundColor: '#D3EAC8',
        textColor: '#345427',
    },
    5: {
        iconColor: '#848484',
        backgroundColor: '#E5E5E5',
        textColor: '#303035',
    },
};

// Function to format the date in the notify Component
export function formatNotifyDate(dateString) {
  const months = [
      "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
      "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
  ];

  const [day, month, ano] = dateString.split("/");

  return `${day} ${months[parseInt(month, 10) - 1]}`;
}

// mock plans
export const plans = [
  {
      planName: 'FreeZap',
      planPrice: 0.00,
      description: '',
      messagesPerMonth: 600,
      resources: [{ name: 'Envios condicionais', have: true}, { name: 'Envios programados', have: true}, { name: 'Dashboard de Leitura/Recebimento', have: true}],
      dataSource: [{ name: 'Planilhas do Google', have: true}, { name: 'Integrações API', have: false}],
      packageIncluded: [{ name: 'WhatsApp API', have: false}, { name: 'WhatsApp Web: 2.000 envios por mês', have: true}, { name: 'E-Mails: 2.000 envios por mês', have: true}, { name: 'SMS', have: false }]
  },
  {
      planName: 'StartZap',
      planPrice: 249.00,
      description: 'Ideal para pequenas empresas que querem disparar até 2.000 mensagens por mês para seus clientes.',
      messagesPerMonth: 2000,
      resources: [{ name: 'Envios condicionais', have: true}, { name: 'Envios programados', have: true}, { name: 'Dashboard de Leitura/Recebimento', have: true}],
      dataSource: [{ name: 'Planilhas do Google', have: true}, { name: 'Integrações API', have: false}],
      packageIncluded: [{ name: 'WhatsApp API', have: false}, { name: 'WhatsApp Web: 2.000 envios por mês', have: true}, { name: 'E-Mails: 2.000 envios por mês', have: true}, { name: 'SMS', have: false }]
  },
  {
      planName: 'ProZap',
      planPrice: 349.90,
      description: 'Ideal para médias empresas que querem enviar mensagens em massa para uma base maior de clientes.',
      messagesPerMonth: 4000,
      resources: [{ name: 'Envios condicionais', have: true}, { name: 'Envios programados', have: true}, { name: 'Dashboard de Leitura/Recebimento', have: true}],
      dataSource: [{ name: 'Planilhas do Google', have: true}, { name: 'Integrações API', have: true}],
      packageIncluded: [{ name: 'WhatsApp API: 10.000 envios por mês', have: true}, { name: 'WhatsApp Web: 4.000 envios por mês', have: true}, { name: 'E-Mails: 2.000 envios por mês', have: true}, { name: 'SMS: 500 envios por mês', have: true }]
  },
  {
      planName: 'UltraZap',
      planPrice: 649.90,
      description: 'Ideal para grandes empresas que buscam uma ferramenta de disparo de whatsapp robusta e ilimitada.',
      messagesPerMonth: 'ilimitadas',
      resources: [{ name: 'Envios condicionais', have: true}, { name: 'Envios programados', have: true}, { name: 'Dashboard de Leitura/Recebimento', have: true}],
      dataSource: [{ name: 'Planilhas do Google', have: true}, { name: 'Integrações API', have: true}],
      packageIncluded: [{ name: 'WhatsApp API: ilimitado', have: true}, { name: 'WhatsApp Web: 4.000 envios por mês', have: true}, { name: 'E-Mails: 10.000 envios por mês', have: true}, { name: 'SMS: 1.000 envios por mês', have: true }]
  },
];

// mock pack
export const packs = [
  {
      name: 'Pacote de email',
      price: 3,
      messageNumber: 1000
  },
  {
      name: 'Pacote de SMS',
      price: 7,
      messageNumber: 100
  }
];