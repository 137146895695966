// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
 
Desc: This is the CSS file for the PapperBlock component.
update: 12/08/2024

*/

.title {
    text-transform: uppercase;
    font-size: 24px;
    line-height: 1.6;
    font-weight: 100;  
    color: #01579B;
    margin-bottom: 0; /* Reduz o espaço entre title e desc */
    
}
  
.desc {
    line-height: 1.6;
    color: #3d3d3d;
    margin-top: 0; /* Reduz o espaço entre title e desc */
}
`, "",{"version":3,"sources":["webpack://./src/components/layouts/PapperBlock.css"],"names":[],"mappings":"AAAA;;;;;CAKC;;AAED;IACI,yBAAyB;IACzB,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,cAAc;IACd,gBAAgB,EAAE,sCAAsC;;AAE5D;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa,EAAE,sCAAsC;AACzD","sourcesContent":["/*\n \nDesc: This is the CSS file for the PapperBlock component.\nupdate: 12/08/2024\n\n*/\n\n.title {\n    text-transform: uppercase;\n    font-size: 24px;\n    line-height: 1.6;\n    font-weight: 100;  \n    color: #01579B;\n    margin-bottom: 0; /* Reduz o espaço entre title e desc */\n    \n}\n  \n.desc {\n    line-height: 1.6;\n    color: #3d3d3d;\n    margin-top: 0; /* Reduz o espaço entre title e desc */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
