// Description: Component that render the package data
// Updated:
// Author: Simon Franklin

// React
import React from 'react'

// Material UI Components
import { Box, Button, TextField, Typography } from '@mui/material'
import themeColors from '../ThemeColors/ThemeColors'
import StatusIcon from '../StatusLabel/StatusIcon'

// Components and Functions for the project

const PackageCard = ({ pack, packNumber, setPackNumber }) => {
    const handleAdd = () => {
        setPackNumber((prev) => prev + 1);
        console.log("adicionou")
    }

    const handleRemove = () => {
        if (packNumber > 0) {
            setPackNumber((prev) => prev - 1);
        }
        console.log("removeu")
    }

    return (
        <Box sx={{ width: { xs: "100%", sm: "310px" }, height: "410px", background: "#FFF", border: "1px solid #DFDFDF", borderRadius: "8px", padding: "43px 35px 33px 35px", display: "flex", flexDirection: "column", fontWeight: "500", boxShadow: "0 15px 25px rgba(0, 0, 0, 0.15)" }}>
            <Typography sx={{ fontSize: { xs: "16px", sm: "20px" }, color: themeColors.purple500, marginBottom: "16px", fontWeight: "500", display: "flex", alignItems: "center", gap: "5px" }}>
                {pack.name === "Pacote de email" ? <StatusIcon status={"Email"} color={themeColors.purple500} width={"26px"} height={"26px"} /> : <StatusIcon status={"SMS"} color={themeColors.purple500} width={"26px"} height={"26px"} />}{pack.name}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "end", marginBottom: "16px" }}>
                <Typography sx={{ fontSize: { xs: "20px", sm: "24px" }, color: "#000", fontWeight: "600" }}>
                    R${packNumber * pack.price},00
                </Typography>
            </Box>

            <Box sx={{ width: "100%", border: "1px solid #848484", height: "1px", marginBottom: "16px" }} />

            <Box sx={{ display: "flex", flexDirection: "column", gap: "30px", flex: 1 }}>
                <Typography sx={{ fontSize: { xs: "12px", sm: "14px" }, color: "#848484" }}>
                    {pack.messageNumber} mensagens
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "center", gap: "20px" }}>
                    <Box sx={{
                        cursor: "pointer", transition: ".4s ease", opacity: packNumber === 0 ? "0.4" : "1", "&:hover": {
                            transform: "scale(1.1)"
                        }
                    }}
                        onClick={handleRemove}
                    >
                        <StatusIcon status={"Minus"} color={"#000"} width={"24px"} height={"24px"} />
                    </Box>
                    <Box sx={{ pointerEvents: "none" }}>{packNumber}</Box>
                    <Box sx={{
                        cursor: "pointer", transition: ".4s ease", "&:hover": {
                            transform: "scale(1.1)"
                        }
                    }}
                        onClick={handleAdd}
                    >
                        <StatusIcon status={"Plus"} color={"#000"} width={"24px"} height={"24px"} />
                    </Box>
                </Box>
            </Box>
            <Button sx={{ width: "100%", height: "41.95px", borderRadius: "6px", border: "1px solid #B7B7B7", color: themeColors.purple500, fontWeight: "600" }}>
                Comprar pacote
            </Button>
        </Box>
    )
}

export default PackageCard