// Description: Page to create campaigns in the help mode
// Updated: 20/01/2025
// Author: Simon Franklin

// React
import React, { useEffect, useState } from "react";

// Material UI Components
import { Box } from "@mui/material";

// Components and functions for the project
import HelpDynamicForm from "../../components/DynamicForm/HelpDynamicForm";
import HeaderPage from "../../components/HeaderPage/HeaderPage";
import useDynamicForm from "../../components/DynamicForm/useHelpDynamicForm";
import { isAuthenticated } from "../../authMiddleware";
import { GetAllTemplates } from "../../api/backend/Templates/GetAllTemplates";
import { GetListagens } from "../../api/backend/Listagens/GetListagens";
import { Channels } from "../../api/backend/Channels/Channels";
import { CampaignSave } from "../../api/backend/Campaigns/CampaignSave";
import axios from "axios";
import { initialCampaignFields } from "./initialCampaignHelpFields";
import { useNavigate } from "react-router-dom";
import { notify } from "../../utils/utils";

const AddCampaignHelp = () => {
  const { authenticated } = isAuthenticated();

  // Verify if the user is creating a campaign
  const creatingCampaignWithHelpMode = JSON.parse(sessionStorage.getItem("creating_campaign")) || null;
  const returningToCampaignWithHelpMode = JSON.parse(sessionStorage.getItem("ReturToCampaign")) || null;

  const [initialFields, setInitialFields] = useState(JSON.parse(sessionStorage.getItem("initial_fields")) || initialCampaignFields);
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [dynamicDate, setDynamicDate] = useState(false);

  const navigate = useNavigate();

  const steps = [
    {
      stepNumber: 1,
      next: false,
      redirect: false,
      redirectPath: "",
      pathText: "",
      onSubmit: () => { },
      title: "Vamos criar sua campanha?",
      description:
        "Comece definindo o título, a descrição e os horários de início da campanha, ou seja, quando os clientes receberão a mensagem.",
    },
    {
      stepNumber: 2,
      next: false,
      redirect: true,
      redirectPath: "/canais/adicionar",
      pathText: "Adicionar Canal",
      onSubmit: () => { },
      title: "Selecionar canal",
      description:
        "Nessa etapa você vai definir os canais que vão ser utilizados, se não tiver nenhum clique em “Adicionar Canal”. Após a adição você será redirecionado para essa página.",
    },
    {
      stepNumber: 3,
      next: false,
      redirect: true,
      redirectPath: "/modelos/canal",
      pathText: "Adicionar Modelo",
      onSubmit: () => { },
      title: "Selecionar modelo",
      description:
        "Defina os modelos a serem utilizados na campanha ou clique em 'Adicionar modelo' para criar. Após a criação, o processo até aqui será salvo e você será redirecionado para continuar a criação da campanha.",
    },
    {
      stepNumber: 4,
      next: false,
      redirect: true,
      redirectPath: "/listagens/adicionar",
      pathText: "Adicionar Listagem",
      onSubmit: () => { },
      title: "Selecionar Listagem",
      description:
        "Vamos escolher a lista utilizada na campanha ou clique em “Adicionar Listagem” para criar uma, após a criação você será redirecionado para essa página",
    },
    {
      stepNumber: 5,
      next: false,
      redirect: false,
      redirectPath: "",
      pathText: "",
      onSubmit: () => { },
      title: "Configurações finais",
      description:
        "Vamos finalizar as configurações da campanha como as regras de envio e as datas dinâmicas",
    },
  ];

  const {
    currentStep,
    stepData,
    fields,
    formState,
    handleNextStep,
    prevStep,
    updateFieldValue,
    setFormState
  } = useDynamicForm(initialFields, steps);

  useEffect(() => {
    if (authenticated) {
      const { decryptedData } = isAuthenticated();
      setUserInfo(decryptedData);
    }
  }, []);

  useEffect(() => {
    if (creatingCampaignWithHelpMode !== true) {
      fetchLists();
      fetchTemplates();
      fetchChannels();
    }

    if (returningToCampaignWithHelpMode === true) {
      setLoading(true)
      if (currentStep === 2) {
        fetchChannels();
      } else if (currentStep === 3) {
        fetchTemplates();
      } else if (currentStep === 4) {
        fetchLists();
      } else if (currentStep === 5) {
        getColumns();
      }

      setTimeout(() => {
        setLoading(false);
      }, 700)
    }
  }, []);

  useEffect(() => {
    if (formState["Selecionar Listagem"] !== "") {
      getColumns();
    }
  }, [formState["Selecionar Listagem"]]);

  useEffect(() => {
    const fieldsToCheck = [
      {
        key: "Deseja enviar pelo WhatsApp?",
        targets: [
          { field: "Qual modelo vai usar no WhatsApp?", originalType: "select" },
        ],
      },
      {
        key: "Deseja enviar pelo SMS?",
        targets: [
          { field: "Qual modelo vai usar no SMS?", originalType: "select" },
        ],
      },
      {
        key: "Deseja enviar pelo Email?",
        targets: [
          { field: "Qual modelo vai usar no Email?", originalType: "select" },
        ],
      },
      {
        key: "Datas de envio dinâmicas",
        targets: [
          { field: "Em qual coluna estão as datas?", originalType: "select" },
          { field: "Enviar somente em dias úteis?", originalType: "select" },
          { field: "Qual será a referência de envio?", originalType: "select" },
          { field: "Quantos dias antes ou depois?", originalType: "text" },
        ],
      },
      {
        key: "Deseja adicionar regra de envio?",
        targets: [
          { field: "Coluna da regra", originalType: "select" },
          { field: "Operador", originalType: "select" },
          { field: "Valor", originalType: "text" },
        ],
      },
    ];

    setInitialFields((prev) =>
      prev.map((field) => {
        for (const { key, targets } of fieldsToCheck) {
          const target = targets.find((t) => t.field === field.fieldName);
          if (target) {
            const currentValue = formState[key];
            const newType = currentValue === false ? "block" : target.originalType;
            return { ...field, type: newType };
          }
        }
        return field;
      })
    );

    fieldsToCheck.forEach(({ key, targets }) => {
      if (formState[key] === false) {
        setFormState((prev) => {
          const updates = {};
          targets.forEach(({ field }) => {
            updates[field] = false;
          });
          return { ...prev, ...updates };
        });
      }
    });
  }, [formState]);

  const fetchLists = async () => {
    try {
      const resultJson = await GetListagens();

      console.log(resultJson);

      const listagens = resultJson.map((listagem) => {
        return {
          id: listagem[0],
          icon: listagem[7],
          title: listagem[1],
          desc: listagem[2],
          type: listagem[7],
          link: listagem[5],
        };
      });

      console.log(listagens);

      setInitialFields((prev) =>
        prev.map((field) =>
          field.fieldName === "Selecionar Listagem" ? { ...field, options: listagens } : field
        )
      )
    } catch (error) {
      console.error("Erro ao obter dados da API:", error);
    }
  };

  const fetchChannels = async () => {
    try {
      const resultJson = await Channels();

      const whatsAppChannels = resultJson.status
        .filter(
          (item) =>
            item.integration === "WhatsApp" ||
            item.integration === "WhatsApp WEB"
        )
        .map((item) => ({
          id: item.id,
          title: item.owner,
        }));

      whatsAppChannels.unshift({ id: false, title: "Não enviar" });

      const smsChannels = resultJson.status
        .filter((item) => item.integration === "SMS")
        .map((item) => ({
          id: item.id,
          title: item.owner,
        }));

      smsChannels.unshift({ id: false, title: "Não enviar" });

      const emailChannels = resultJson.status
        .filter((item) => item.integration === "E-mail")
        .map((item) => ({
          id: item.id,
          title: item.owner,
        }));

      emailChannels.unshift({ id: false, title: "Não enviar" });

      setInitialFields((prev) =>
        prev.map((field) => {
          if (field.fieldName === "Deseja enviar pelo WhatsApp?") {
            return { ...field, options: whatsAppChannels };
          }
          if (field.fieldName === "Deseja enviar pelo SMS?") {
            return { ...field, options: smsChannels };
          }
          if (field.fieldName === "Deseja enviar pelo Email?") {
            return { ...field, options: emailChannels };
          }
          return field;
        })
      );

      console.log("channels:", whatsAppChannels, smsChannels, emailChannels);
    } catch (error) {
      console.error("Erro ao obter status da instância:", error);
    }
  };

  const fetchTemplates = async () => {
    try {
      const resultJson = await GetAllTemplates();

      console.log("modelos encontrados:", resultJson);

      const items = resultJson
        .map((item) => {
          return {
            id: item.id,
            title: item.title,
            uuidAccount: item.uuid_account,
            integration_icons: item.channel_type,
            status: item.status,
            msg_type: item.msg_type,
            channel_type: item.channel_type,
            visibility: item.visibility,
          };
        })
        .filter((item) => item !== null && item.visibility === true);

      console.log("models:", items);

      setInitialFields((prev) =>
        prev.map((field) => {
          if (field.fieldName === "Qual modelo vai usar no WhatsApp?") {
            return {
              ...field,
              options: items.filter(
                (model) => model.channel_type === "WhatsApp"
              )
            };
          }
          if (field.fieldName === "Qual modelo vai usar no SMS?") {
            return {
              ...field,
              options: items.filter(
                (model) => model.channel_type === "SMS"
              )
            };
          }
          if (field.fieldName === "Qual modelo vai usar no Email?") {
            return {
              ...field,
              options: items.filter(
                (model) => model.channel_type === "E-mail"
              )
            };
          }
          return field;
        })
      );
    } catch (error) {
      console.error("Erro ao obter modelos:", error);
    } finally {
    }
  };

  const getColumns = async () => {
    const listagens = initialFields.find((field) => field.fieldName === "Selecionar Listagem").options;

    const response = await axios.post(
      "https://api.123zap.com.br/webhook/get-variables",
      {
        uuid_account: userInfo["UUID"],
        full_token: userInfo["TOKEN"],
        listagem: listagens.find((listagem) => listagem.id === formState["Selecionar Listagem"])
          .link,
        id: userInfo["EMAIL"],
        listSelected: formState["Selecionar Listagem"],
      }
    );

    console.log(response.data.data);

    const items = response.data.data.map((item) => {
      return {
        id: item.id,
        title: item.name,
        column: item.column,
      };
    });

    setInitialFields((prev) =>
      prev.map((field) =>
        (field.fieldName === "Em qual coluna estão as datas?" || field.fieldName === "Coluna") ? { ...field, options: items } : field
      )
    )
  };

  const getFieldsForStep = () => {
    if (currentStep === 5) {
      return dynamicDate
        ? fields.slice(-4)
        : fields.slice(0, 5);
    }
    return fields;
  };

  const saveCampaign = async () => {
    const lists = initialFields.find((field) => field.fieldName === "Selecionar Listagem").options;

    let data = {
      title: formState["Título da Campanha"] ? formState["Título da Campanha"] : null,
      desc: formState["Descrição da Campanha"] ? formState["Descrição da Campanha"] : null,
      startDate: formState["Data de início"] ? formState["Data de início"] : null,
      endDate: formState["Data de término"] ? formState["Data de término"] : null,
      minSendTime: formState["Horário de início"] ? formState["Horário de início"] : null,
      maxSendTime: null,
      whatsapp_id: formState["Deseja enviar pelo WhatsApp?"] ? formState["Deseja enviar pelo WhatsApp?"] : null,
      whatsAppModel: formState["Qual modelo vai usar no WhatsApp?"] ? formState["Qual modelo vai usar no WhatsApp?"] : null,
      sms_id: formState["Deseja enviar pelo SMS?"] ? formState["Deseja enviar pelo SMS?"] : null,
      smsModel: formState["Qual modelo vai usar no SMS?"] ? formState["Qual modelo vai usar no SMS?"] : null,
      email_id: formState["Deseja enviar pelo Email?"] ? formState["Deseja enviar pelo Email?"] : null,
      emailModel: formState["Qual modelo vai usar no Email?"] ? formState["Qual modelo vai usar no Email?"] : null,
      listSelected: formState["Selecionar Listagem"] ? formState["Selecionar Listagem"] : null,
      type: formState["Tipo de Envio"] ? formState["Tipo de Envio"] : null,
      dateDynamic: formState["Datas de envio dinâmicas"] ? true : false,
      dateColumn: formState["Em qual coluna estão as datas?"] ? formState["Em qual coluna estão as datas?"] : null,
      weekDays: formState["Enviar somente em dias úteis?"] ? formState["Enviar somente em dias úteis?"] : false,
      sendReference: formState["Qual será a referência de envio?"] ? formState["Qual será a referência de envio?"] : null,
      daysBeforeAfter: formState["Quantos dias antes ou depois?"] ? formState["Quantos dias antes ou depois?"] : null,
      sendRule: formState["Deseja adicionar regra de envio?"] ? formState["Deseja adicionar regra de envio?"] : false,
      column: formState["Deseja adicionar regra de envio?"] ? formState["Deseja adicionar regra de envio?"] : null,
      operator: formState["Operador"] ? formState["Operador"] : null,
      value: formState["Valor"] ? formState["Valor"] : null,
      uuid_account: userInfo['UUID'],
      list_type: lists.find(listagem => listagem.id === formState["Selecionar Listagem"]).type,
    }

    const response = await CampaignSave(data);

    console.log(response, 'response');

    if (response.status === 200) {
      navigate('/campanhas');
      notify("Success", "Campanha criada com sucesso!", true);
    }

    sessionStorage.removeItem("formState");
    sessionStorage.removeItem("currentStep");
    sessionStorage.removeItem("initial_fields");
    sessionStorage.removeItem("creating_campaign");
    sessionStorage.removeItem("ReturToCampaign");
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <HeaderPage />
      <HelpDynamicForm
        title={stepData.title}
        description={stepData.description}
        fields={getFieldsForStep()}
        formState={formState}
        nextStep={(currentStep === 5 && !dynamicDate) ? () => setDynamicDate(true) : (currentStep === 5 && dynamicDate === true) ? saveCampaign : handleNextStep}
        prevStep={currentStep === 5 && dynamicDate === true ? () => setDynamicDate(false) : prevStep}
        steps={steps}
        currentStep={currentStep}
        updateFieldValue={updateFieldValue}
        stepData={stepData}
        loading={loading}
        initialFields={initialFields}
        finishing={currentStep === 5 && dynamicDate === true ? true : false}
      />
    </Box>
  );
};

export default AddCampaignHelp;
