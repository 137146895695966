import axios from 'axios';

export async function ProfileGet(data) {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/profile`,
            data,
            { headers: { 'Content-Type': 'application/json' } }
        );
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}