import { Box, Typography } from "@mui/material";
import React from "react";
import { formatCurrency } from "../../utils/utils";

const Resume = ({
    product,
    months,
    economy,
    subtotal
}) => {
    return (
        <Box
            sx={{
                display: "flex",
                width: { xs: "100%", sm: "35%" },
                padding: "23px 32px",
                borderRadius: "16px",
                background: "#FFF",
                flexDirection: "column",
                height: "auto",
                maxHeight: "653.2px"
            }}
        >
            <Box
                sx={{ display: "flex", flexDirection: "column", height: "100%" }}
            >
                <Typography
                    sx={{
                        fontSize: { xs: "20px", sm: "24px" },
                        color: "#38216A",
                        fontWeight: 600,
                        marginBottom: "44px",
                    }}
                >
                    Resumo do pedido
                </Typography>
                <Typography
                    sx={{
                        fontSize: { xs: "16px", sm: "20px" },
                        color: "#38216A",
                        fontWeight: 600,
                        marginBottom: "16px",
                    }}
                >
                    {product.name}
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "33px",
                    }}
                >
                    {product.features.map((feature) => (
                        <Typography
                            key={feature}
                            children={feature}
                            sx={{
                                fontSize: { xs: "13px", sm: "16px" },
                                color: "#38216A"
                            }}
                        />
                    ))}
                </Box>

                <Box
                    sx={{
                        border: "1px solid #38216A",
                        width: "100%",
                        marginBottom: "25px",
                    }}
                />

                <Typography
                    sx={{
                        fontSize: { xs: "16px", sm: "20px" },
                        color: "#38216A",
                        fontWeight: 600,
                        marginBottom: "26px",
                    }}
                >
                    Subtotal
                </Typography>

                <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "33px" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography
                            sx={{
                                fontSize: { xs: "14px", sm: "18px" },
                                color: "#38216A"
                            }}
                        >
                            Valor Geral
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: { xs: "14px", sm: "18px" },
                                color: "#38216A",
                                textDecoration: "line-through"
                            }}
                        >
                            {formatCurrency(product.price * months, "BRL")}
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography
                            sx={{
                                fontSize: { xs: "14px", sm: "18px" },
                                color: "#38216A"
                            }}
                        >
                            Descontos
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: { xs: "14px", sm: "18px" },
                                color: "#00B090"
                            }}
                        >
                            - {formatCurrency(economy, "BRL")}
                        </Typography>
                    </Box>
                </Box>

                <Box
                    sx={{
                        border: "1px solid #38216A",
                        width: "100%",
                        marginBottom: "25px",
                    }}
                />

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography
                        sx={{
                            fontSize: { xs: "16px", sm: "24px" },
                            color: "#38216A",
                            fontWeight: 600,
                        }}
                    >
                        Total
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: { xs: "16px", sm: "24px" },
                            color: "#38216A",
                            fontWeight: 600,
                        }}
                    >
                        {formatCurrency(subtotal, 'BRL')}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default Resume