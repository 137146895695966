// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 

This file contains the styles for the Sidebar component.
Update: 12/08/2024

*/

.sidebar {
  width: 300px;
  background-color: #FFFFFF;
  min-height: 99vh;
  transition: width 0.3s ease;
  box-sizing: border-box;
  position: fixed;  
  top: 0; 
  left: 0;  
  bottom: 0;  
  overflow-y: auto; 
  box-shadow: 4px 0 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.sidebar.collapsed {
  width: 100px;
  padding-right: 0;
}

.sidebar.expanded {
  width: 220px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar a:visited {
  color: inherit;
}


@media screen and ( max-width: 960px ) {
  .sidebar {
    width: 100px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/layouts/Sidebar.css"],"names":[],"mappings":"AAAA;;;;;CAKC;;AAED;EACE,YAAY;EACZ,yBAAyB;EACzB,gBAAgB;EAChB,2BAA2B;EAC3B,sBAAsB;EACtB,eAAe;EACf,MAAM;EACN,OAAO;EACP,SAAS;EACT,gBAAgB;EAChB,wCAAwC;EACxC,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,SAAS;AACX;;AAEA;EACE,cAAc;AAChB;;;AAGA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":["/* \n\nThis file contains the styles for the Sidebar component.\nUpdate: 12/08/2024\n\n*/\n\n.sidebar {\n  width: 300px;\n  background-color: #FFFFFF;\n  min-height: 99vh;\n  transition: width 0.3s ease;\n  box-sizing: border-box;\n  position: fixed;  \n  top: 0; \n  left: 0;  \n  bottom: 0;  \n  overflow-y: auto; \n  box-shadow: 4px 0 4px rgba(0, 0, 0, 0.1);\n  z-index: 1000;\n}\n\n.sidebar.collapsed {\n  width: 100px;\n  padding-right: 0;\n}\n\n.sidebar.expanded {\n  width: 220px;\n}\n\n.sidebar ul {\n  list-style: none;\n  padding: 0;\n  margin: 0;\n}\n\n.sidebar a:visited {\n  color: inherit;\n}\n\n\n@media screen and ( max-width: 960px ) {\n  .sidebar {\n    width: 100px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
