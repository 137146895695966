import React from "react";

const Emoji13 = (props) => (
    <svg
    id="Camada_2"
    data-name="Camada 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 208.9 208.9"
    width={props.width || '85'} height={props.height || '85'}
>
    <defs>
      <style>
        {
          `\n      .cls-1 {\n        fill: #210d33;\n      }\n\n      .cls-1, .cls-2 {\n        stroke-width: 0px;\n      }\n\n      .cls-3 {\n        fill: ${props.fill || '#7B25FF'};\n      }\n    `
        }
      </style>
    </defs>
    <g id="Layer_1" data-name="Layer 1">
      <g>
        <path
          className="cls-3"
          d="M153.9,0H55C24.62,0,0,24.62,0,55v98.9c0,30.37,24.62,55,55,55h98.9c30.38,0,55-24.63,55-55V55c0-30.38-24.62-55-55-55ZM61.93,89.21c-6.77,0-12.25-6.19-12.25-13.86s5.48-13.85,12.25-13.85,12.27,6.2,12.27,13.85-5.5,13.86-12.27,13.86ZM104.47,147.4c-12.37,0-22.42-11.3-22.42-25.3s10.05-25.31,22.42-25.31,22.38,11.34,22.38,25.31-10.01,25.3-22.38,25.3ZM146.97,89.21c-6.77,0-12.27-6.19-12.27-13.86s5.5-13.85,12.27-13.85,12.25,6.2,12.25,13.85-5.48,13.86-12.25,13.86Z"
        />
        <path
          className="cls-2"
          d="M74.2,75.35c0,7.67-5.5,13.86-12.27,13.86s-12.25-6.19-12.25-13.86,5.48-13.85,12.25-13.85,12.27,6.2,12.27,13.85Z"
        />
        <path
          className="cls-2"
          d="M126.85,122.1c0,14-10.01,25.3-22.38,25.3s-22.42-11.3-22.42-25.3,10.05-25.31,22.42-25.31,22.38,11.34,22.38,25.31Z"
        />
        <path
          className="cls-2"
          d="M159.22,75.35c0,7.67-5.48,13.86-12.25,13.86s-12.27-6.19-12.27-13.86,5.5-13.85,12.27-13.85,12.25,6.2,12.25,13.85Z"
        />
        <g>
          <g>
            <path
              className="cls-1"
              d="M74.2,75.35c0,7.67-5.5,13.86-12.27,13.86s-12.25-6.19-12.25-13.86,5.48-13.85,12.25-13.85,12.27,6.2,12.27,13.85Z"
            />
            <path
              className="cls-1"
              d="M159.22,75.35c0,7.67-5.48,13.86-12.25,13.86s-12.27-6.19-12.27-13.86,5.5-13.85,12.27-13.85,12.25,6.2,12.25,13.85Z"
            />
          </g>
          <path
            className="cls-1"
            d="M126.85,122.1c0,14-10.01,25.3-22.38,25.3s-22.42-11.3-22.42-25.3,10.05-25.31,22.42-25.31,22.38,11.34,22.38,25.31Z"
          />
        </g>
      </g>
    </g>
  </svg>
   

); export default Emoji13