import { Box, TextField, Typography, Button } from "@mui/material";
import React from "react";
import themeColors from "../../../components/ThemeColors/ThemeColors";
import {
  insertMaskForCep,
  insertMaskForPhoneNumber,
  notify
} from "../../../utils/utils";
import Resume from "../Resume";

const Step2 = ({
  name,
  street,
  city,
  cep,
  state,
  setName,
  setStreet,
  setCity,
  setCep,
  setState,
  number,
  setNumber,
  product,
  months,
  setCurrentStep,
  surname,
  setSurname,
  cel,
  setCel,
  phone,
  setPhone,
  economy,
  subtotal
}) => {
  const handleAdvance = (e) => {
    e.preventDefault();

    if (name && street && cep && state && city && phone && cel && surname) {
      setCurrentStep(3);
    } else {
      notify("Error", "Preencha todos os campos", true);
    }
  };

  const fetchAddressInfo = async (cepToSearch) => {
    // Regex to validate the cep
    const validacep = /^[0-9]{8}$/;

    // Fetching the cep data
    if (validacep.test(cepToSearch)) {
      const res = await fetch(`https://viacep.com.br/ws/${cepToSearch}/json/`);

      if (!res.ok)
        throw new Error("Ocorreu algum erro, tente novamente mais tarde");

      const addressInfo = await res.json();

      setState(addressInfo.estado);
      setCity(addressInfo.localidade);
      setStreet(addressInfo.logradouro);
    } else {
      return;
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
      <Box
        component={"form"}
        sx={{ display: "flex", gap: "28px", flexDirection: { xs: "column", sm: "row" } }}
        onSubmit={handleAdvance}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            flex: "1"
          }}
        >
          <Box
            sx={{
              display: "flex",
              padding: "30px",
              borderRadius: "16px",
              background: "#FFF",
              justifyContent: "space-between",
              flexDirection: "column",
              gap: "2.5rem",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Box
                sx={{
                  fontSize: { xs: "14px", sm: "18px" },
                  color: themeColors.purple500,
                  fontWeight: 600,
                  border: "1px solid #38216A",
                  width: "36px",
                  height: "36px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                1
              </Box>
              <Typography
                sx={{
                  fontSize: { xs: "20px", sm: "24px" },
                  color: "#38216A",
                  fontWeight: 600,
                }}
              >
                Endereço de faturamento
              </Typography>
            </Box>

            <Box
              sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <TextField
                  autoFocus
                  variant="outlined"
                  value={name}
                  sx={{
                    background: "#FFF",
                    border: "1.5px solid #d7d7d7",
                    borderRadius: "6px",
                    width: "100%",
                    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input' : {
                      padding: "13px 12px"
                    }
                  }}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Primeiro Nome"
                />
                <TextField
                  variant="outlined"
                  value={surname}
                  sx={{
                    background: "#FFF",
                    border: "1.5px solid #d7d7d7",
                    borderRadius: "6px",
                    width: "100%",
                    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input' : {
                      padding: "13px 12px"
                    }
                  }}
                  onChange={(e) => setSurname(e.target.value)}
                  placeholder="Sobrenome"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <TextField
                  variant="outlined"
                  value={insertMaskForPhoneNumber(phone)}
                  sx={{
                    background: "#FFF",
                    border: "1.5px solid #d7d7d7",
                    borderRadius: "6px",
                    width: "100%",
                    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input' : {
                      padding: "13px 12px"
                    }
                  }}
                  onChange={(e) => {
                    const removingLetters = e.target.value.replace(/\D/g, "");
                    setPhone(removingLetters)
                  }}
                  placeholder="Telefone"
                />
                <TextField
                  variant="outlined"
                  value={insertMaskForPhoneNumber(cel)}
                  sx={{
                    background: "#FFF",
                    border: "1.5px solid #d7d7d7",
                    borderRadius: "6px",
                    width: "100%",
                    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input' : {
                      padding: "13px 12px"
                    }
                  }}
                  onChange={(e) => {
                    const removingLetters = e.target.value.replace(/\D/g, "");
                    setCel(removingLetters)
                  }}
                  placeholder="Celular"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <TextField
                  variant="outlined"
                  value={insertMaskForCep(cep)}
                  sx={{
                    background: "#FFF",
                    border: "1.5px solid #d7d7d7",
                    borderRadius: "6px",
                    width: "100%",
                    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input' : {
                      padding: "13px 12px"
                    }
                  }}
                  onChange={(e) => {
                    const onlyNumber = e.target.value.replace(/\D/g, "");
                    setCep(onlyNumber);
                    fetchAddressInfo(onlyNumber);
                  }}
                  placeholder="CEP"
                />
                <TextField
                  variant="outlined"
                  value={city}
                  sx={{
                    background: "#FFF",
                    border: "1.5px solid #d7d7d7",
                    borderRadius: "6px",
                    width: "100%",
                    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input' : {
                      padding: "13px 12px"
                    }
                  }}
                  onChange={(e) => setCity(e.target.value)}
                  placeholder="Cidade"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <TextField
                  variant="outlined"
                  value={street}
                  sx={{
                    background: "#FFF",
                    border: "1.5px solid #d7d7d7",
                    borderRadius: "6px",
                    width: "100%",
                    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input' : {
                      padding: "13px 12px"
                    }
                  }}
                  onChange={(e) => setStreet(e.target.value)}
                  placeholder="Rua"
                />
                <TextField
                  variant="outlined"
                  value={number}
                  sx={{
                    background: "#FFF",
                    border: "1.5px solid #d7d7d7",
                    borderRadius: "6px",
                    width: "100%",
                    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input' : {
                      padding: "13px 12px"
                    }
                  }}
                  onChange={(e) => setNumber(e.target.value)}
                  placeholder="Número"
                />
              </Box>
            </Box>

            <Button
              variant="contained"
              type="submit"
              sx={{
                height: "48px",
                borderRadius: "6px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "211.75px",
              }}
            >
              Continuar
            </Button>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              aignItems: "center",
              borderRadius: "16px",
              background: "#FFF",
              height: "124px",
              padding: "30px",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Box
                sx={{
                  fontSize: { xs: "14px", sm: "18px" },
                  color: themeColors.purple500,
                  fontWeight: 600,
                  border: "1px solid #38216A",
                  width: "36px",
                  height: "36px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                2
              </Box>
              <Typography
                sx={{
                  fontSize: { xs: "20px", sm: "24px" },
                  color: "#38216A",
                  fontWeight: 600,
                  opacity: "60%",
                }}
              >
                Pagamento
              </Typography>
            </Box>
          </Box>
        </Box>

        <Resume economy={economy} months={months} product={product} subtotal={subtotal} />
      </Box>
    </Box>
  );
};

export default Step2;
