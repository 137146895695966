import React, { useState, useEffect } from 'react';
import { Avatar, Grid, Typography, IconButton, TextField, Select, MenuItem, FormControl, InputLabel, Button } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import { isAuthenticated } from '../../authMiddleware';
import AlternativeAvatar from '../../assets/images/avatar-padrao.png';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import {ProfileGet} from '../../api/backend/Auth/ProfileGet';

const Profile = () => {
    const { userAuthenticated, userInfo, setUserInfo } = useAuth();
    const [loading, setLoading] = useState(true);
    const [isAvatarLoaded, setIsAvatarLoaded] = useState(false);
    const [userAvatar, setUserAvatar] = useState(AlternativeAvatar);
    const [name, setName] = useState("John Doe");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [cellPhone, setCellPhone] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [state, setState] = useState("");
    const [number, setNumber] = useState("");
    const [isLegalPerson, setIsLegalPerson] = useState("");
    const [cpfOrCnpj, setCpfOrCnpj] = useState("");
    const [newAvatar, setNewAvatar] = useState(null); 

    async function getUserData() {
        try {
            const uuid_client = userInfo['UUID'];
            const response = await ProfileGet({ uuid_client }); 

            setName(response.name);
            setEmail(response.email);
            setCpfOrCnpj(response.cpf_cnpj);
            setIsLegalPerson(response.person_type);
            setZipCode(response.postal_code);
            setState(response.state);
            setNumber(response.number);
            setAddress(response.street);
            setPhone(response.phone);
            setCellPhone(response.cellphone);

        } catch (error) {
            console.error('Erro ao buscar os dados do usuário:', error);
        }
    };

    useEffect(() => {
        getUserData();
    }, []);
    
    useEffect(() => {
        if (userAuthenticated) {
            const { decryptedData } = isAuthenticated();
            setUserInfo(decryptedData);
        }
    }, [userAuthenticated]);

    const fetchUserAvatar = async (userHash) => {
        try {
            const imageUrl = 'https://files123zap.s3.amazonaws.com/avatar_users/' + userHash;
            const img = new Image();
            img.onload = () => {
                setUserAvatar(imageUrl);
                setIsAvatarLoaded(true);
                setLoading(false);
            };
            img.onerror = () => {
                console.error('Erro ao carregar a imagem:', imageUrl);
                setUserAvatar(AlternativeAvatar);
                setIsAvatarLoaded(true);
                setLoading(false);
            };
            img.src = imageUrl;
        } catch (error) {
            console.error('Erro ao buscar o avatar do usuário:', error);
            setUserAvatar(AlternativeAvatar);
            setIsAvatarLoaded(true);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (userInfo['UUID']) {
            const userHash = userInfo['UUID'] + '.png';
            fetchUserAvatar(userHash);
        }
    }, [userInfo]);

    const handleEditAvatar = (event) => {
        console.log('Evento:', event);
        const file = event.target.files[0];
        if (file && file.type === 'image/png') {
            const reader = new FileReader();
            reader.onload = (e) => {
                setNewAvatar(e.target.result);
                setUserAvatar(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSaveProfile = () => {

        console.log('Perfil salvo!'); 
    };

    return (
        <div>
            <Grid container spacing={2} justifyContent="center" direction="column" alignItems="center">
                <Grid item style={{ position: 'relative' }}>
                    <Avatar
                        src={userAvatar}
                        sx={{ width: 200, height: 200 }}
                    />
                    <input
                        type="file"
                        accept="image/png"
                        onChange={handleEditAvatar}
                        style={{
                            display: 'none',
                        }}
                        id="avatar-input"
                    />
                    <label htmlFor="avatar-input">
                        <IconButton
                            component="span"
                            sx={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0,
                                bgcolor: 'white',
                                borderRadius: '50%',
                                boxShadow: 2,
                            }}
                        >
                            <CameraAltIcon sx={{ fontSize: 30 }} />
                        </IconButton>
                    </label>
                </Grid>

                <Grid item>
                    <Typography variant="h5" align="center">{name}</Typography>
                    <Typography variant="body1" align="center">{email}</Typography>
                </Grid>

                <Grid item container spacing={2} xs={12} sx={{ marginTop: 2 }}>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth sx={{ backgroundColor: 'white', border: '1px solid #D9D9DC', borderRadius: 1 }}>
                            <InputLabel id="legal-person-label">Tipo de Pessoa</InputLabel>
                            <Select
                                labelId="legal-person-label"
                                value={isLegalPerson}
                                onChange={(e) => setIsLegalPerson(e.target.value)}
                                label="Tipo de Pessoa"
                            >
                                <MenuItem value="F">Pessoa Física</MenuItem>
                                <MenuItem value="J">Pessoa Jurídica</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label={isLegalPerson === 'pessoaJuridica' ? "CNPJ" : "CPF"}
                            variant="outlined"
                            value={cpfOrCnpj}
                            onChange={(e) => setCpfOrCnpj(e.target.value)}
                            sx={{ backgroundColor: 'white' , border: '1px solid #D9D9DC', borderRadius: 1 }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Telefone"
                            variant="outlined"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            sx={{ backgroundColor: 'white', border: '1px solid #D9D9DC', borderRadius: 1 }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Celular"
                            variant="outlined"
                            value={cellPhone}
                            onChange={(e) => setCellPhone(e.target.value)}
                            sx={{ backgroundColor: 'white' , border: '1px solid #D9D9DC', borderRadius: 1 }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="CEP"
                            variant="outlined"
                            value={zipCode}
                            onChange={(e) => setZipCode(e.target.value)}
                            sx={{ backgroundColor: 'white' , border: '1px solid #D9D9DC', borderRadius: 1 }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Endereço"
                            variant="outlined"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            sx={{ backgroundColor: 'white', border: '1px solid #D9D9DC', borderRadius: 1 }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Estado"
                            variant="outlined"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                            sx={{ backgroundColor: 'white' , border: '1px solid #D9D9DC', borderRadius: 1 }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Número"
                            variant="outlined"
                            value={number}
                            onChange={(e) => setNumber(e.target.value)}
                            sx={{ backgroundColor: 'white', border: '1px solid #D9D9DC', borderRadius: 1 }}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12} style={{ marginTop: 20 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveProfile}
                        fullWidth
                    >
                        Salvar Perfil
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default Profile;
