import { Box, Typography } from '@mui/material';
import React from 'react';

const SubPage = ({ page }) => {
  return (
    <Box
      sx={{
        padding: '49px 94px',
        display: 'flex',
        flexDirection: 'column',
        gap: '45px',
        width: '100%',
        flex: '1'
      }}
    >
      {page}
    </Box>
  );
}

export default SubPage;
