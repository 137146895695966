import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import themeColors from '../../components/ThemeColors/ThemeColors'
import StatusIcon from '../../components/StatusLabel/StatusIcon'

const PixModal = ({ open, qrCode, pixkey, onClose }) => {
    return (
        <>
            <Box onClick={onClose} sx={{ width: "100vw", height: "100vh", background: "#000", opacity: "50%", display: open ? "block" : "none", position: "fixed", top: 0, left: 0, zIndex: 10 }} />
            <Box sx={{ width: "484px", height: "481px", background: "#FFF", display: open ? "block" : "none", position: "fixed", borderRadius: "12px", top: "50%", left: "50%", transform: "translate(-50%, -50%)", padding: "40px", display: open ? "flex" : "none", flexDirection: "column", alignItems: "center", zIndex: 10 }}>
                <Typography sx={{ fontWeight: 500, color: themeColors.purple500, fontSize: { xs: "18px", sm: "24px" }, marginBottom: "23px" }}>
                    Realize o pagamento!
                </Typography>
                <Typography sx={{ fcolor: themeColors.gray40, fontSize: { xs: "14px", sm: "16px" }, marginBottom: "23px", textAlign: "center" }}>
                    Realize a leitura do QR Code ou insira a chave no seu aplicativo bancário para realizar o pagamento.
                </Typography>
                <Box component="img" src={qrCode} sx={{ width: "211px", height: "211px", objectFit: "contain", marginBottom: "25px" }} />

                <Button variant="contained" sx={{ padding: "13px 60px", color: "#FFF", borderRadius: "6px", fontSize: { xs: "14px", sm: "16px" }, margin: "0 auto" }}>
                    Copiar Chave PIX
                </Button>

                <Box onClick={onClose} sx={{ position: "absolute", top: "20px", right: "20px", cursor: "pointer" }}>
                    <StatusIcon status={"Close"} color={"#1E1E1E"} width={"20px"} height={"20px"} />
                </Box>
            </Box>
        </>
    )
}

export default PixModal